// import axios from "axios";
import { MAIN_URL } from "../../app/config";
// import { setDownloadVoiceMessagesProgress } from "./fileSlice";

const API_URL = MAIN_URL + "/api/files/"

const downloadVoiceMessage = async (voice_message_url) => {

    try
    {
        const response = await fetch(API_URL + `convert?webmUrl=${encodeURIComponent(voice_message_url)}`, {
            // const response = await fetch(API_URL + `convert?webmUrl=${voice_message_url}`, {
                method: "GET",
            });

            if (!response.ok)
            {
                throw new Error("Failed to convert voice message to M4A.");
            }

            // Turn the response into a Blob
            // const blob = await response.blob();

            // return blob;

            // Read the response as a Blob
            const blob = await response.blob();

            // Create a temporary URL and download link
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            // link.setAttribute("download", "output.ogg");
            link.setAttribute("download", "output.m4a");

            // Append to the DOM and trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            // Return the response
            // return response;
            return "Downloaded voice message successfully.";
        }
    catch (error)
    {
        console.error("Download error:", error);
        // alert('Failed to download file.');
        return null;
    }

    // try
    // {
    //     const response = await fetch(API_URL + "upload", {
    //         method: "POST",
    //         body: voice_message_data,
    //     });

    //     if (!response.ok)
    //     {
    //         throw new Error("Failed to upload voice message.");
    //     }

    //     const data = await response.json();

    //     return data.url; // This is the URL of the uploaded file in GCS

    // }
    // catch (error)
    // {
    //     console.error("Error uploading voice message:", error);
    //     return null;
    // }

};

const fileService = { downloadVoiceMessage };

export default fileService;