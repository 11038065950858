import ChartViewerYellow from "./ChartViewerYellow";
import ChartViewerRed from "./ChartViewerRed";

const ChartViewer = ({ chart_data, class_level }) => {

  const this_chart_data = chart_data ? chart_data : null;
  const this_class_level = class_level ? class_level : null;
  
  return (
    <>
    {
        this_chart_data && this_class_level &&
        (this_class_level === "Yellow") ? (<ChartViewerYellow chart_data={this_chart_data} />) :
        (this_class_level === "Pink") ? (<ChartViewerRed chart_data={this_chart_data} />) :
        (this_class_level === "Red") ? (<ChartViewerRed chart_data={this_chart_data} />) :
        (this_class_level === "Pre-Blue") ? (<ChartViewerRed chart_data={this_chart_data} />) :
        (this_class_level === "Blue") ? (<ChartViewerRed chart_data={this_chart_data} />) :
        (this_class_level === "Green") ? (<ChartViewerRed chart_data={this_chart_data} />) :
        (this_class_level === "HEEP") ? (<ChartViewerRed chart_data={this_chart_data} />) :
        (this_class_level === "HE L1") ? (<ChartViewerRed chart_data={this_chart_data} />) :
        (this_class_level === "HE L2") ? (<ChartViewerRed chart_data={this_chart_data} />) :
        (this_class_level === "HI") ? (<ChartViewerRed chart_data={this_chart_data} />) :
        (this_class_level === "Reading") ? (<ChartViewerRed chart_data={this_chart_data} />) :
        (<ChartViewerRed chart_data={this_chart_data} />)
    }
    </>
);
};

export default ChartViewer;