import { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FooterTeacher from "./FooterTeacher";
import FooterAdmin from "../for_admins/FooterAdmin";
import UpdateReportFormYellow from "./report_components/UpdateReportFormYellow";
import UpdateReportFormRed from "./report_components/UpdateReportFormRed";
import { can_access_page } from "../../app/user_utils";
import { FaPen, FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import RecordAndUploadVoiceComponent from "./RecordAndUploadVoiceComponent";
import UploadMediaComponent from "./UploadMediaComponent";
import { fetchReportById, updateReport, resetUpdateReportState, resetFetchReportState } from "../../features/reports/reportSlice";
import ImageViewer from "../ImageViewer";
import VideoViewer from "../VideoViewer";
import { PATH_NAME, WEEK_NUMBERS_JUNIOR, WEEK_NUMBERS_SENIOR, TOAST_ERROR_MS, CLASS_LEVEL } from "../../app/constants";
import { UI_STRING_LOADING_ALL,
        UI_STRING_REPORT_ALL,
        UI_STRING_REPORT_RED } from "../../app/strings";
import { isClassSenior } from "../../app/utils";
import { ReportContentData_Yellow, ReportContentData_Red,  } from "./report_components/ReportContentData";

const UpdateReportPage = () => {
    const { report_id, class_level, edit_mode } = useParams();

    const this_report_id = report_id ? report_id : null;
    const this_class_level = class_level ? class_level : null;
    const is_in_edit_mode = edit_mode ? edit_mode : false;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);

    const { fetched_report, fetch_status, is_updating_report, is_updated_report } = useSelector((state) => state.reports);

    const [ canRenderForm, setCanRenderForm ] = useState(false);
    const [ formElement, setFormElement ] = useState(null);

    const enter_view_mode = useCallback(() => {

        // Remove /edit_mode from the URL
        navigate( PATH_NAME.Admin_Update_Report + `/${this_class_level}/${this_report_id}` );

    }, [this_class_level, this_report_id, navigate]);

    const week_numbers_array = useMemo(() => {

        return isClassSenior(this_class_level) ? WEEK_NUMBERS_SENIOR : WEEK_NUMBERS_JUNIOR;

    }, [ this_class_level]);

    const DEFAULT_REPORT_CONTENT = useMemo(() => {

        if (this_class_level === CLASS_LEVEL.Yellow)
        {
            return ReportContentData_Yellow;
        }
        else if (this_class_level === CLASS_LEVEL.Red)
        {
            return ReportContentData_Red;
        }

        return ReportContentData_Red;

    }, [this_class_level]);

    const [formData, setFormData] = useState({
        // week_number: week_numbers_array[0].value,
        voice_message_urls: "",
        photo_urls: [],
        video_urls: [],
        content: { ...DEFAULT_REPORT_CONTENT },
        is_completed: false,
        // More fields to be added here
        // checkboxField1: false,
        // commentBoxField1: '',
    });

    const preventFormSubmitOnEnter = useCallback((event) => {
        if (event.key === "Enter")
        {
            // Prevent form submission
            event.preventDefault();
            return false;
        }
    }, []);

    useEffect(() => {
        // Attach event listener when formElement is set
        if (formElement)
        {
            formElement.addEventListener("keydown", preventFormSubmitOnEnter);

            // Remove event listener on cleanup
            return () => {
                formElement.removeEventListener("keydown", preventFormSubmitOnEnter);
            };
        }
    }, [formElement, preventFormSubmitOnEnter]);

    useEffect(() => {
        // When the component is mounted, set the form element
        setFormElement(document.getElementById("update_report_form"));
    }, []);

    useEffect(() => {
        if (this_report_id)
        {
            dispatch( fetchReportById(this_report_id) );
        }
        else
        {
            // console.log("No report ID provided. Redirecting...");

            navigate( PATH_NAME.Teacher_Dashboard );
        }

    }, [this_report_id, dispatch, navigate]);

    useEffect(() => {
        if (fetch_status === "succeeded" && fetched_report)
        {
            setFormData({
                week_number: fetched_report.week_number,
                voice_message_urls: fetched_report.voice_message_urls || "",
                photo_urls: fetched_report.photo_urls || [],
                video_urls: fetched_report.video_urls || [],
                content: {
                    ...DEFAULT_REPORT_CONTENT, // Start with default values
                    ...fetched_report.content // Override with fetched data
                },
                is_completed: fetched_report.is_completed || false,
                // checkboxField1: report.checkboxField1 || false,
                // commentBoxField1: report.commentBoxField1 || '',
                // Set other fields based on the report data
            });

            // var element = document.getElementById("week_number");

            // // Manually update the selected value of the dropdown as well
            // if (element)
            // {
            //     element.value = fetched_report.week_number;
            // }

            // Can safely render the form now
            setCanRenderForm(true);
        }
    }, [fetch_status, fetched_report, DEFAULT_REPORT_CONTENT]);

    useEffect(() => {

        if (is_updated_report)
        {
            dispatch( resetFetchReportState())
            dispatch( resetUpdateReportState() );

            navigate( PATH_NAME.Teacher_Dashboard );
        }
    }, [is_updated_report, dispatch, navigate]);

    // const handleChange = (e) => {
    //     const { name, value, type, checked } = e.target;

    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [name]: type === "checkbox" ? checked : value,
    //     }));
    // };

    const handleContentChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        setFormData((prevFormData) => ({
            ...prevFormData,
            content: {
                ...prevFormData.content,
                // If checkbox, check boolean. Otherwise, set value
                [name]: type === "checkbox" ? checked : value,
            }
        }));
    };

    const handleVoiceMessagesUploadComplete = (uploadedVoiceMessageUrls) => {

        if (!uploadedVoiceMessageUrls || uploadedVoiceMessageUrls.length === 0)
        {
            return;
        }

        if (this_report_id === null)
        {
            return;
        }
        
        setFormData((prevFormData) => ({
            ...prevFormData,
            voice_message_urls: uploadedVoiceMessageUrls,
        }));

        // console.log("UpdateReportForm RED >> handleVoiceMessageUploadComplete -- Voice Message URL: ", uploadedVoiceMessageUrl);
    };

    const handleImageUploadComplete = (uploadedPhotoUrls) => {

        if (!uploadedPhotoUrls || uploadedPhotoUrls.length === 0)
        {
            return;
        }

        if (this_report_id === null)
        {
            return;
        }
        
        setFormData((prevFormData) => ({
            ...prevFormData,
            photo_urls: uploadedPhotoUrls,
        }));
        // setImageUrls(uploadedUrls);
        // Save these URLs for report update

        // console.log("UpdateReportForm RED >> handleImageUploadComplete -- Image URLs: ", uploadedPhotoUrls);
    };

    const handleVideoUploadComplete = (uploadedVideoUrls) => {

        if (!uploadedVideoUrls || uploadedVideoUrls.length === 0)
        {
            return;
        }

        if (this_report_id === null)
        {
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            video_urls: uploadedVideoUrls,
        }));

        console.log("handleVideoUploadComplete -- Video URLs: ", uploadedVideoUrls);
    };


    const handleSubmit = (e) => {

        e.preventDefault();

        // Centre requirement checks
        if ((parseInt(formData.week_number) === week_numbers_array.length)
            && (!formData.voice_message_urls || formData.voice_message_urls.length === 0))
        {
            // console.log("UpdateReportFormRed.js >> handleSubmit :: need voice messages, week_number: ", formData.week_number);

            toast.error(UI_STRING_REPORT_ALL.Toast_Error_Missing_Voice_Message, {
                autoClose: TOAST_ERROR_MS,
            });

            return;
        }

        // More centre requirement checks
        if ( isClassSenior(this_class_level)
            && (parseInt(formData.week_number) === week_numbers_array.length)
            && (!formData.photo_urls || formData.photo_urls.length === 0))
        {
            // console.log("UpdateReportFormRed.js >> handleSubmit :: need quiz photos, week_number: ", formData.week_number);

            toast.error(UI_STRING_REPORT_ALL.Toast_Error_Missing_Quiz_Photos, {
                autoClose: TOAST_ERROR_MS,
            });

            return;
        }

        if ( formData.content.weekly_comment === "" )
        {
            toast.error(UI_STRING_REPORT_ALL.Toast_Error_Missing_Comment, {
                autoClose: TOAST_ERROR_MS,
            });

            return;
        }

        // Todo: some levels only
        if ( this_class_level === CLASS_LEVEL.Red && formData.content.key_object === "" )
        {
            toast.error(UI_STRING_REPORT_RED.Toast_Error_Missing_Key_Object, {
                autoClose: TOAST_ERROR_MS,
            });

            return;
        }

        // Todo: some levels only
        if ( this_class_level === CLASS_LEVEL.Red && formData.content.key_letter === "" )
        {
            toast.error(UI_STRING_REPORT_RED.Toast_Error_Missing_Key_Letter, {
                autoClose: TOAST_ERROR_MS,
            });

            return;
        }

        const updated_report = { ...fetched_report,
                                ...formData,
                                is_completed: true,
                            };

        // console.log("UpdateReportPage.js >> handleSubmit :: updated_report = ", updated_report);
        
        dispatch( updateReport(updated_report) );
    };

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (user.role === "admin" || user.role === "manager")
        {
            // console.log("User is admin or manager. Entering view mode...");

            enter_view_mode();
        }
        else if (can_access_page(user, PATH_NAME.Teacher_Update_Report) === false)
        {
            // console.log("User has no permission. Redirecting to dashboard...");

            navigate( PATH_NAME.Dashboard );
        }
    }, [user, enter_view_mode, navigate]);

    if (is_updating_report)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Saving_Report }/>;
    }

    return (

        (!fetched_report || fetch_status === "loading") ? <Spinner /> :
        (
        !canRenderForm ? (<></>) :
        (
        <>
        <div className="bg-gradient-to-b from-hots-green-1 to-hots-green-2 ... text-center">
            <div className="login_container">

                <section className="headless_title mb-12">

                    <h1 className="flex items-center justify-center">

                        {
                            edit_mode === "edit_mode" ?
                            (
                                <>
                                <FaPen className="mr-8"/> {UI_STRING_REPORT_ALL.Update_Report}
                                </>
                            ) :
                            (
                                <>
                                <FaEye className="mr-8"/> {UI_STRING_REPORT_ALL.View_Report}
                                </>
                            )
                        }
                    </h1>
                
                </section>

                <section className="headless_title mb-12">
                    <p className="my-6 text-hots-purple font-extrabold text-4xl">
                        {fetched_report && fetched_report.student && fetched_report.student.student_name}
                    </p>
                    <p className="my-6 text-gray-500 font-bold text-3xl">
                        Preferred Name: {fetched_report && fetched_report.student && fetched_report.student.preferred_name}
                    </p>
                    <p className="my-6 text-white font-bold text-2xl">
                        ({ fetched_report && fetched_report.student && fetched_report.student.student_id })
                    </p>
                </section>
                
                <section className="form">
                    <form id="update_report_form"
                            onKeyDown={preventFormSubmitOnEnter}
                    >

                        <hr className="mt-8 mb-8 thin"></hr>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.The_Teacher}</label>

                            <label className="text-gray-300 font-medium text-xl">
                                {fetched_report && fetched_report.by_teacher && fetched_report.by_teacher.display_name}
                            </label>
                        </div>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Report_Status}</label>

                            {fetched_report.is_completed ?
                            (
                                <label className="text-gray-300 font-medium text-xl">
                                    {UI_STRING_REPORT_ALL.Completed}
                                </label>
                            ) : (
                                <label className="text-hots-red font-medium text-xl">
                                    {UI_STRING_REPORT_ALL.Incomplete}
                                </label>
                            )}
                        </div>

                        <hr className="mt-8 mb-8 thin"></hr>
                        
                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Class_Level}</label>

                            <label className="text-gray-300 font-medium text-xl uppercase">
                                {fetched_report && fetched_report.lesson && fetched_report.lesson.class_level}
                            </label>

                        </div>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Week_Number}</label>
                            
                            <label className="text-gray-300 font-medium text-xl uppercase">
                                {UI_STRING_REPORT_ALL.The_Week + " " + (fetched_report && fetched_report.week_number)}
                            </label>
                            
                        </div>

                        <hr className="mt-8 mb-8 thin"></hr>
                        
                        {/* Class level specific form fields */}
                        {
                            this_report_id && this_class_level &&
                            (this_class_level === "Yellow") ? (<UpdateReportFormYellow
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (this_class_level === "Pink") ? (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (this_class_level === "Red") ? (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (this_class_level === "Pre-Blue") ? (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (this_class_level === "Blue") ? (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (this_class_level === "Green") ? (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (this_class_level === "HEEP") ? (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (this_class_level === "HE L1") ? (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (this_class_level === "HE L2") ? (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (this_class_level === "HI") ? (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (this_class_level === "Reading") ? (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />) :
                            (<UpdateReportFormRed
                                formData={formData} handleContentChange={handleContentChange} is_in_edit_mode={is_in_edit_mode} />)
                        }

                        <hr className="mt-8 mb-8 regular"></hr>
                        
                        {/* Only show recorder if there are no photos already */}
                        {(formData.voice_message_urls?.length === 0) && (
                        <>
                        <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">
                                {UI_STRING_REPORT_ALL.Voice_Messages_Record_Prompt}
                            </label>
                            
                            { is_in_edit_mode &&
                            (
                            <RecordAndUploadVoiceComponent
                                // inputAudioURL={formData.voice_message_urls}
                                onUploadComplete={ handleVoiceMessagesUploadComplete } />
                            )}

                        </div>
                        </>
                        )}

                        {/* Otherwise, display the voice messages */}
                        {(formData.voice_message_urls && formData.voice_message_urls.length > 0) && (
                        <>
                            <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">
                                {UI_STRING_REPORT_ALL.Uploaded_Voice_Messages}
                            </label>
                            
                            {formData.voice_message_urls.map((this_vm_url, index) => (

                                <div key={index} className="my-12">
                                    
                                    <div className="flex items-center space-x-4"> {/* Flexbox to align side by side with spacing */}

                                    <audio src={this_vm_url} controls />

                                    {/* <span className="text-lg font-medium text-gray-600">Uploaded</span> */}

                                    </div>
                                </div>
                            ))}

                            </div>
                        </>
                        )}

                        <hr className="mt-8 mb-8 regular"></hr>

                        {/* Only show uploader if there are no photos already */}
                        {(formData.photo_urls?.length === 0) && (
                        <>
                            <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">
                                {UI_STRING_REPORT_ALL.Photo_Upload_Prompt}
                            </label>
                            
                            { is_in_edit_mode &&
                            (
                            <UploadMediaComponent
                                fileType={"image"}
                                onUploadComplete={ handleImageUploadComplete }
                            />
                            )}

                            </div>
                        </>
                        )}

                        {/* Otherwise, display the images */}
                        {(formData.photo_urls && formData.photo_urls.length > 0) && (
                        <>
                            <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Uploaded_Photos}</label>
                            
                            <ImageViewer photo_urls={formData.photo_urls} />

                            </div>
                        </>
                        )}

                        <hr className="mt-8 mb-8 regular"></hr>

                        {/* Only show uploader if there are no videos already */}
                        {(formData.video_urls?.length === 0) && (
                        <>
                            <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">
                                {UI_STRING_REPORT_ALL.Video_Upload_Prompt}
                            </label>

                            { is_in_edit_mode &&
                            (
                            <UploadMediaComponent
                                fileType={"video"}
                                onUploadComplete={ handleVideoUploadComplete }
                            />
                            )}

                            </div>
                        </>
                        )}

                        {/* Otherwise, display the video */}
                        {(formData.video_urls && formData.video_urls.length > 0) && (
                        <>
                            <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Uploaded_Videos}</label>

                            <VideoViewer videoUrls={formData.video_urls} />

                            </div>
                        </>
                        )}

                        <hr className="mt-8 mb-8 regular"></hr>

                        {
                        is_in_edit_mode && (
                            <>
                            <div className="form_group mt-12">
                                <button className="btn btn_block" onClick={handleSubmit}>
                                    {UI_STRING_REPORT_ALL.Button_Save_Report}
                                </button>
                            </div>

                            <Link to={ PATH_NAME.Teacher_Dashboard } className="flex justify-center mt-8 mb-16">
                                <button className="btn_gray w-full">
                                    {UI_STRING_REPORT_ALL.Button_Cancel}
                                </button>
                            </Link>
                            </>
                        )}
                    </form>
                </section>

            </div>

        {
            user && (user.role === "admin" || user.role === "manager") &&
            <FooterAdmin />
        }

        {
            user && user.role === "teacher" &&
            <FooterTeacher />
        }
        </div>
        </>
        )
    )
    );
};

export default UpdateReportPage;
