import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { PATH_NAME } from "../../../app/constants";

const LessonAccordion = ({ this_lesson, index }) => {

    const [is_open, set_is_open] = useState(false);

    const navigate = useNavigate();

    const toggle_accordion = () => {
        set_is_open(!is_open);
    };

    const go_to_student_page = (student_id, student_name) => () => {

        // console.log("LessonAccordion.js >> go_to_student_page :: student_id: ", student_id);

        navigate(PATH_NAME.Admin_Student_Reports + "/" + student_id + "/" + student_name);
    };

    return (
        <>
            <tr key={index} className="pl-4 bg-white cursor-pointer" onClick={toggle_accordion}>
                <td className="px-4 py-4 whitespace-nowrap rounded-l-xl">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-extrabold text-left text-hots-purple">
                                {is_open ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                        </div>
                    </div>
                </td>

                <td className="px-4 py-4 whitespace-nowrap">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-medium text-left text-gray-900">
                                {this_lesson.class_level}
                            </div>
                        </div>
                    </div>
                </td>

                <td className="px-4 py-4 whitespace-nowrap font-medium">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-medium text-left text-hots-red">
                                {this_lesson.room}
                            </div>
                        </div>
                    </div>
                </td>

                <td className="px-4 py-4 whitespace-nowrap font-medium">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-medium text-left text-gray-900">
                                {this_lesson.teacher ? this_lesson.teacher.display_name : "Filler Teacher"}
                            </div>
                        </div>
                    </div>
                </td>

                <td className="px-4 py-4 whitespace-nowrap font-medium rounded-r-xl">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-medium text-left text-hots-purple">
                                Week {this_lesson.week_number}
                            </div>
                        </div>
                    </div>
                    {/* <button className="btn_item">
                        <FaPen />
                    </button> */}
                </td>
            </tr>

            {is_open && (
                <tr className="pl-4 bg-gray-200">
                    <td colSpan="6" className="px-6 py-4 whitespace-nowrap rounded-bl-xl rounded-br-xl rounded-tr-xl">
                        <div className="flex flex-col items-left">
                            <div className="ml-4">
                                <div className="font-medium text-left text-gray-900">Attendance:</div>
                                {this_lesson.students && this_lesson.students.length > 0 ? (
                                    <ul className="list-disc ml-6">
                                        {this_lesson.students.map((curr_student, idx) => (
                                            <li key={idx} className="text-left text-gray-700
                                                                    hover:cursor-pointer hover:text-hots-green-1"
                                                onClick={ go_to_student_page(curr_student.student_id, curr_student.student_name) }
                                            >
                                                {curr_student.student_name + " (" + curr_student.student_id + ")"}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="text-left text-gray-700">No students assigned to this lesson.</div>
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default LessonAccordion;