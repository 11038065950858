import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from "./authService";

const local_user = JSON.parse(localStorage.getItem("user"));

const initialState_register = {
    is_registering: false,
    is_registered: false,
    is_error_registering: false,
    message_for_register: ""
};

const initialState_login = {
    is_logging_in: false,
    is_logged_in: false,
    is_error_logging_in: false,
    message_for_login: ""
};

const initialState_verifyToken = {
    is_token_verifying: false,
    is_token_verified: false,
    is_token_failed: false,
    message_for_verifying_token: ""
};

const initialState_registerTeacher = {
    new_teacher: null,
    is_registering_teacher: false,
    is_registered_teacher: false,
    is_error_registering_teacher: false,
    message_for_register_teacher: ""
};

const initialState_getAllTeachers = {
    teachers: [],
    is_loading_all_teachers: false,
    is_loaded_all_teachers: false,
    is_error_loading_all_teachers: false,
    message_for_loading_all_teachers: ""
};

const initialState = {
    user: local_user ? local_user : null,
    ...initialState_register,
    ...initialState_login,
    ...initialState_verifyToken,
    ...initialState_registerTeacher,
    ...initialState_getAllTeachers
};

export const register = createAsyncThunk(
    "auth/register",
    async (user, thunkAPI) => {
        try
        {
            return await authService.register(user);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const registerTeacher = createAsyncThunk(
    "auth/register_teacher",
    async (teacher_user, thunkAPI) => {
        try
        {
            return await authService.registerTeacher(teacher_user);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async (user, thunkAPI) => {
        try
        {
            return await authService.login(user);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const logout = createAsyncThunk(
    "auth/logout",
    async () => await authService.logout()
);

export const checkToken = createAsyncThunk(
    "auth/check_token",
    async (token, thunkAPI) => {
        try
        {
            // console.log("authSlice.js >> checkToken :: token = " + token);

            const response = await authService.checkToken(token);

            // console.log(response);

            if (response)
            {
                // console.log("authSlice.js >> checkToken :: response = " + response);

                if (response.error)
                {
                    // console.log("authSlice.js >> checkToken :: error = " + response.error);

                    return thunkAPI.rejectWithValue( response.error );
                }
                
                return response;
            }
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getAllTeachers = createAsyncThunk(
    "auth/get_all_teachers",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            // console.log("authSlice >> getAllTeachers :: token -- " + token);
            return await authService.getAllTeachers(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        resetAuth: state => initialState,
        resetRegisterState: state => initialState_register,
        resetLoginState: state => initialState_login,
        resetVerifyTokenState: state => initialState_verifyToken,
        resetRegisterTeacherState: state => initialState_registerTeacher,
        resetGetAllTeachersState: state => initialState_getAllTeachers
    },
    extraReducers: builder => {
        builder
            .addCase(register.pending, (state) => {
                state.is_registering = true;
                state.is_registered = false;
                state.is_error_registering = false;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.is_registering = false;
                state.is_registered = true;
                state.is_error_registering = false;
                state.user = action.payload;
            })
            .addCase(register.rejected, (state, action) => {
                state.is_registering = false;
                state.is_registered = false;
                state.is_error_registering = true;
                state.message_for_register = action.payload;
                state.user = null;
            })
            .addCase(registerTeacher.pending, (state) => {
                state.is_registering_teacher = true;
                state.is_registered_teacher = false;
                state.is_error_registering_teacher = false;
            })
            .addCase(registerTeacher.fulfilled, (state, action) => {
                state.is_registering_teacher = false;
                state.is_registered_teacher = true;
                state.is_error_registering_teacher = false;
                state.new_teacher = action.payload;
            })
            .addCase(registerTeacher.rejected, (state, action) => {
                state.is_registering_teacher = false;
                state.is_registered_teacher = false;
                state.is_error_registering_teacher = true;
                state.new_teacher = null;
                state.message_for_register_teacher = action.payload;
            })
            .addCase(login.pending, (state) => {
                state.is_logging_in = true;
                state.is_logged_in = false;
                state.is_error_logging_in = false;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.is_logging_in = false;
                state.is_logged_in = true;
                state.is_error_logging_in = false;
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.is_logging_in = false;
                state.is_logged_in = false;
                state.is_error_logging_in = true;
                state.message_for_login = action.payload;
                state.user = null;
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
            })
            .addCase(checkToken.pending, (state) => {
                state.is_token_verifying = true;
                state.is_token_verified = false;
                state.is_token_failed = false;
            })
            .addCase(checkToken.fulfilled, (state, action) => {
                state.is_token_verified = true;
                state.is_token_verifying = false;
                state.is_token_failed = false;
                state.message_for_verifying_token = action.payload;
            })
            .addCase(checkToken.rejected, (state, action) => {
                state.is_token_verified = false;
                state.is_token_verifying = false;
                state.is_token_failed = true;
                state.message_for_verifying_token = action.payload;
            })
            .addCase(getAllTeachers.pending, (state) => {
                state.is_loading_all_teachers = true;
                state.is_loaded_all_teachers = false;
                state.is_error_loading_all_teachers = false;
            })
            .addCase(getAllTeachers.fulfilled, (state, action) => {
                state.is_loading_all_teachers = false;
                state.is_loaded_all_teachers = true;
                state.is_error_loading_all_teachers = false;
                // console.log("authSlice.js >> fulfilled :: " + action.payload);
                state.teachers = action.payload;
            })
            .addCase(getAllTeachers.rejected, (state, action) => {
                state.is_loading_all_teachers = false;
                state.is_loaded_all_teachers = false;
                state.is_error_loading_all_teachers = true;
                state.message_for_loading_all_teachers = action.payload;
                state.teachers = [];
            }); 
    }
});

export const {
                resetAuth,
                resetRegisterState,
                resetLoginState,
                resetVerifyTokenState,
                resetRegisterTeacherState,
                resetGetAllTeachersState
            } = authSlice.actions;
export default authSlice.reducer;