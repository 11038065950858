import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import adminAlertService from "./adminAlertService";
// import authService from '../auth/authService';

const initialState = {
    admin_alerts: [],
    is_error: false,
    is_success: false,
    is_loading: false,
    is_reading: false,
    is_closing: false,
    is_closed: false,
    message: ""
};

export const createAdminAlert = createAsyncThunk(
    "admin_alerts/create",
    async (alert_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await adminAlertService.createAdminAlert(alert_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getOpenAdminAlerts = createAsyncThunk(
    "admin_alerts/get_all",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            return await adminAlertService.getOpenAdminAlerts(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const closeAdminAlert = createAsyncThunk(
    "admin_alerts/close",
    async (id, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            // console.log("adminAlertSlice.js >> closeAdminAlert: id = " + id + "; token = " + token);
            return await adminAlertService.closeAdminAlert(id, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const adminAlertSlice = createSlice({
    name: "admin_alerts",
    initialState,
    reducers: {
        reset: state => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAdminAlert.pending, state => {
                state.is_loading = true;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(createAdminAlert.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_success = true;
                state.admin_alerts.push(action.payload);
            })
            .addCase(createAdminAlert.rejected, (state, action) => {
                state.is_loading = false;
                state.is_error = true;
                state.message = action.payload;
            })
            .addCase(getOpenAdminAlerts.pending, (state) => {
                state.is_loading = true;
                state.is_reading = true;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(getOpenAdminAlerts.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_reading = false;
                state.is_success = true;
                state.admin_alerts = action.payload;
            })
            .addCase(getOpenAdminAlerts.rejected, (state, action) => {
                state.is_loading = false;
                state.is_reading = false;
                state.is_error = true;
                state.message = action.payload;
            })
            .addCase(closeAdminAlert.pending, (state) => {
                state.is_loading = true;
                state.is_closing = true;
                state.is_closed = false;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(closeAdminAlert.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_closing = false;
                state.is_success = true;

                // Assuming action.payload contains the id of the closed alert
                const closed_alert_id = action.payload.id;
                
                // Remove the closed alert from the admin_alerts array
                state.admin_alerts = state.admin_alerts.filter(
                    alert => alert._id !== closed_alert_id
                );

                state.is_closed = true;
            })
            .addCase(closeAdminAlert.rejected, (state, action) => {
                state.is_loading = false;
                state.is_closing = false;
                state.is_closed = false;
                state.is_error = true;
                state.message = action.payload;
            });
    }
});

export const { reset } = adminAlertSlice.actions;
export default adminAlertSlice.reducer;