import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import teacherService from './teacherService';

const initialState = {
    teacher: null,
    teachers: [],
    is_reading_teachers: false,
    is_error_reading_teachers: false,
    is_success_reading_teachers: false,
    message: ""
};

export const getTeachersByName = createAsyncThunk(
    "teachers/get_by_name",
    async (teacher_name, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await teacherService.getTeachersByName(teacher_name, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const teacherSlice = createSlice({
    name: "teachers",
    initialState,
    reducers: {
        resetTeachers: state => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTeachersByName.pending, (state) => {
                state.is_reading_teachers = true;
                state.is_success_reading_teachers = false;
                state.is_error_reading_teachers = false;
            })
            .addCase(getTeachersByName.fulfilled, (state, action) => {
                state.is_reading_teachers = false;
                state.is_success_reading_teachers = true;
                state.is_error_reading_teachers = false;
                state.teachers = action.payload;
            })
            .addCase(getTeachersByName.rejected, (state, action) => {
                state.is_reading_teachers = false;
                state.is_success_reading_teachers = false;
                state.is_error_reading_teachers = true;
                state.message = action.payload;
            });
    }
});

export const { resetTeachers } = teacherSlice.actions;
export default teacherSlice.reducer;