import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import settingService from './settingService';

const initialState_fetchSettings = {
    is_fetching_settings: false,
    is_fetched_settings: false,
    is_error_fetching_settings: false,
    message_fetching_settings: "",
};

const initialState = {
    settings: [],
    ...initialState_fetchSettings,
    
};

export const getSettings = createAsyncThunk(
    "settings/get",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await settingService.getSettings(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const settingSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        resetSettings: state => initialState,
        resetFetchSettings: state => initialState_fetchSettings,
    },
    extraReducers: (builder) => {
        builder

            .addCase(getSettings.pending, (state) => {
                state.is_fetching_settings = true;
                state.is_fetched_settings = false;
                state.is_error_fetching_settings = false;
                state.message_fetching_settings = "";
            })
            .addCase(getSettings.fulfilled, (state, action) => {
                state.is_fetching_settings = false;
                state.is_fetched_settings = true;
                state.is_error_fetching_settings = false;
                state.settings = action.payload;
            })
            .addCase(getSettings.rejected, (state, action) => {
                state.is_fetching_settings = false;
                state.is_fetched_settings = false;
                state.is_error_fetching_settings = true;
                state.message_fetching_settings = action.payload;
            });
    }
});

export const { resetSettings,
                resetFetchSettings,
 } = settingSlice.actions;
export default settingSlice.reducer;