// import axios from "axios";
import { MAIN_URL } from "../../app/config";
import { setUploadVoiceMessagesProgress, setUploadImagesProgress, setUploadVideosProgress } from "./gcsUploadSlice";

const API_URL = MAIN_URL + "/api/media_files/"

// const uploadVoiceMessage = async (voice_message_data) => {
//     try
//     {
//         const response = await fetch(API_URL + "upload", {
//             method: "POST",
//             body: voice_message_data,
//         });

//         if (!response.ok)
//         {
//             throw new Error("Failed to upload voice message.");
//         }

//         const data = await response.json();

//         return data.url; // This is the URL of the uploaded file in GCS

//     }
//     catch (error)
//     {
//         console.error("Error uploading voice message:", error);
//         return null;
//     }
// }

const uploadVoiceMessages = async ( blob_data_array, thunkAPI ) => {

    const { dispatch } = thunkAPI;
    const fileType = "voice_message";
    const uploadedUrls = [];

    // console.log("gcsUploadService.js >> uploadVoiceMessages :: blob_data_array = ", blob_data_array);

    for (let i = 0; i < blob_data_array.length; i++)
    {
        const recording = blob_data_array[i];
        const file = recording.blob;
        const audioFormData = new FormData();
        audioFormData.append(fileType, file, `voice_message_${Date.now()}.webm`);

        try
        {
            // console.log("gcsUploadService.js >> uploadVoiceMessages :: file = ", file);

            const response = await fetch(API_URL + "upload_" + fileType, {
                method: "POST",
                body: audioFormData,
            });

            if (!response.ok) {
                throw new Error("Failed to upload voice message.");
            }

            const data = await response.json();
            uploadedUrls.push(data.url);

            dispatch( setUploadVoiceMessagesProgress(((i + 1) / blob_data_array.length) * 100) );
        }
        catch (error)
        {
            console.error("Error uploading voice message:", error);
            uploadedUrls.push(null);
        }
    }

    return uploadedUrls;
}

const uploadImages = async ( image_data_array, thunkAPI ) => {

    const { dispatch } = thunkAPI;
    const fileType = "image";
    const uploadedUrls = [];

    // console.log("gcsUploadService.js >> uploadImages :: image_data_array = ", image_data_array);

    for (let i = 0; i < image_data_array.length; i++)
    {
        const file = image_data_array[i];
        const formData = new FormData();
        formData.append(fileType, file, file.name);

        try
        {
            // console.log("gcsUploadService.js >> uploadImages :: file = ", file);

            const response = await fetch(API_URL + "upload_" + fileType, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to upload image.");
            }

            const data = await response.json();
            uploadedUrls.push(data.url);

            dispatch( setUploadImagesProgress(((i + 1) / image_data_array.length) * 100) );
        }
        catch (error)
        {
            console.error("Error uploading image:", error);
            uploadedUrls.push(null);
        }
    }
    
    return uploadedUrls;
}

const uploadVideos = async ( video_data_array, thunkAPI ) => {

    const { dispatch } = thunkAPI;
    const fileType = "video";
    const uploadedUrls = [];

    // console.log("gcsUploadService.js >> uploadVideos :: video_data_array = ", video_data_array);

    for (let i = 0; i < video_data_array.length; i++)
    {
        const file = video_data_array[i];
        const formData = new FormData();
        formData.append(fileType, file, file.name);

        try
        {
            // console.log("gcsUploadService.js >> uploadVideos :: file = ", file);

            const response = await fetch(API_URL + "upload_" + fileType, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to upload video.");
            }

            const data = await response.json();
            uploadedUrls.push(data.url);

            dispatch( setUploadVideosProgress(((i + 1) / video_data_array.length) * 100) );
        }
        catch (error)
        {
            console.error("Error uploading video:", error);
            uploadedUrls.push(null);
        }
    }
    
    return uploadedUrls;
}

const gcsUploadService = { uploadVoiceMessages, uploadImages, uploadVideos };

export default gcsUploadService;