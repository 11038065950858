import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { getStudentsByID, getStudentsByName, resetStudents } from "../features/students/studentSlice";
import { FaSearch } from "react-icons/fa";
// import { STUDENT_ID_PREFIX } from "../app/constants";
import { UI_STRING_CLASS_SEARCH } from "../app/strings";
import { PATH_NAME } from "../app/constants";

const ClassSearchComponent = ({ teacher }) => {
  // const ClassSearchComponent = ({ teacher, on_result_click }) => {

  // [] means all teachers
  // const the_teacher = teacher;

  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const { students, is_reading_students, is_success_reading_students } = useSelector((state) => state.students);

  const [search_date_query, set_search_date_query] = useState("");
  const [ is_search_date_valid, set_is_search_date_valid ] = useState(false);

  // const [student_results, set_student_results] = useState([]);
  // const [no_results, set_no_results] = useState(false);
  const [debounce_timeout, set_debounce_timeout] = useState(null);

  // const [ show_table, set_show_table ] = useState(false);

  // useEffect(() => {

  //   // Reset everything on component mount or when input is empty
  //   if (search_date_query === "")
  //   {
  //     // console.log("StudentSearchComponent.js >> Clearing input field and results...");
  //     set_student_results([]);
  //     set_no_results(false);
  //     dispatch( resetStudents() );  // Reset the Redux state for students
  //   }
  // }, [search_date_query, dispatch]);

  // useEffect(() => {
        
  //   if (is_success_reading_students)
  //   {
  //     if (students && students.length > 0)
  //     {
  //       // console.log("StudentSearchComponent.js >> Setting loaded results...");

  //       set_student_results(students);
  //       set_no_results(false);
  //     }
  //     else
  //     {
  //       // console.log("StudentSearchComponent.js >> No results...");
  //       set_student_results([]);
  //       set_no_results(true);
  //     }
  //   }

  //   // This represents the unmount function,
  //   // in which it's a good idea to reset to prevent memory leaks.
  //   return () => {

  //     // console.log("StudentSearchComponent.js >> Unmounting, clear student data...");

  //     // Cleanup function to reset state when component unmounts or reloads
  //     dispatch( resetStudents() );
  //   };
    
  // }, [students, is_success_reading_students, dispatch]);

  const valid_input_length = 10;
  const date_regex = /^20\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;  // Regular expression for date in format "YYYY-MM-DD"

  // Locally check input query
  const handle_date_search = async (query) => {

    set_is_search_date_valid(false);

    if (query.length === valid_input_length)
    {
      if (date_regex.test(query) && is_valid_date(query))
      {
        // console.log("StudentSearchComponent.js >> handle_student_search :: encountered a valid date query...", query);

        set_is_search_date_valid(true);
      }
    }
  };

  // Example usage:
  // console.log(is_valid_date('2024-02-29')); // true (leap year)
  // console.log(is_valid_date('2023-02-29')); // false (not a leap year)
  // console.log(is_valid_date('2024-04-31')); // false (April has 30 days)
  function is_valid_date(date_string)
  {
    // Parse the components of the date
    const [year, month, day] = date_string.split('-').map(Number);

    // Create a new date object
    const date = new Date(year, month - 1, day); // JS months are 0-indexed

    // Check if the date is valid
    return date.getFullYear() === year && (date.getMonth() + 1) === month && date.getDate() === day;
  }

  // Handle input change for student search with debounce
  const handle_input_change = (e) => {

    const query = e.target.value;

    // Set latest input string as the search query
    set_search_date_query(query);

    // Reset state when input is cleared
    if (query === "")
    {
      // console.log("StudentSearchComponent.js >> handle_input_change :: empty input...");

      // set_show_table(false);

      // set_student_results([]);
      // set_no_results(false);
      return;
    }

    // Clear any existing timeout
    if (debounce_timeout) clearTimeout(debounce_timeout);

    // Debounce the search:
    // This ensures the search is not triggered on every keystroke but waits until the user has stopped typing for a moment.
    // Set a new timeout for search after 200ms
    const new_timeout = setTimeout(() => {

      // console.log("StudentSearchComponent.js >> handle_input_change :: time to fire handle_student_search...");

      handle_date_search(query);

      // The search is delayed by 100 milliseconds.
      // It doesn't need to be a long delay, as no API calls are made at this point.
    }, 100);

    set_debounce_timeout(new_timeout);
  };

  // Handle Enter key press for search
  const handle_key_down = (e) => {

    if (e.key === "Enter")
    {
      // When the Enter key is pressed, any pending debounce search is cleared,
      // and the search is triggered immediately.

      // Clear the debounce timeout when Enter is pressed
      if (debounce_timeout) clearTimeout(debounce_timeout);

      // console.log("StudentSearchComponent.js >> handle_key_down :: enter pressed, fire handle_student_search...");

      handle_date_search(search_date_query);
    }
  };

  const handle_valid_date_click = () => {

    navigate(PATH_NAME.Admin_Day_Lessons + "/" + search_date_query);

    // if (show_table === false)
    // {
    //   set_show_table(true);
    // }
  };

  return (
      (
      <>
      {/* Class Search Field */}
      <div className="form_group">

          <div className="flex items-center space-x-2 ml-2 mb-4">
            <div className="text-gray-600 font-bold text-xl">
              Format: YYYY-MM-DD
            </div>
            <div className="text-gray-600 font-medium text-lg">
              (e.g. 2024-09-18)
            </div>
          </div>

          <input
              type="text"
              className="form_control"
              placeholder={UI_STRING_CLASS_SEARCH.Search_Prompt}
              value={search_date_query}
              onChange={handle_input_change}
              onKeyDown={handle_key_down}
          />
          {
          is_search_date_valid === false ?
          
          (
            search_date_query.length > 0 &&
            (
            <div className="w-full text-left"
            >
              <div className="flex items-center text-gray-500 font-bold text-2xl my-2">
                <FaSearch className="mr-2" /> 
                <span>{search_date_query}</span>
              </div>
            </div>
            )
          )
          :
          (
            // Link to the valid date's timetable
            <>
            <button 
              type="button" 
              onClick={ handle_valid_date_click }
              className="focus:outline-none w-full text-left"
            >
              <div className="flex items-center text-hots-green-1 font-bold text-2xl my-2">
                <FaSearch className="mr-2" /> 
                <span>{search_date_query}</span>
              </div>
            </button>

            {/* {
            show_table &&
            <LessonTable the_teacher={the_teacher} the_date={ format_date(search_date_query) }/>
            } */}
            
            </>
            
          )
          
          }
          </div>
      </>
      )
  );
};

export default ClassSearchComponent;
