import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
// import { toast } from "react-toastify";
import { getSummariesByStudent, resetGetStudentSummariesState,
          generateSummary, resetGenerateSummary, 
        } from "../../../features/summaries/summarySlice";
import Spinner from "../../Spinner";
import { FaTools, FaLink } from "react-icons/fa";
import { RiAiGenerate } from "react-icons/ri";
import { ImNewTab } from "react-icons/im";
import { getDate_MMDDYYYY_HKT } from "../../../app/utils";
import { PATH_NAME } from "../../../app/constants";
import { UI_STRING_LOADING_ALL } from "../../../app/strings";

const StudentSummariesTable = ({ student_id }) => {

  const curr_student_id = student_id ? student_id : null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [canRenderTable, setCanRenderTable] = useState(false);

  const { student_summaries, is_loaded_student_summaries } = useSelector((state) => state.summaries);

  const { output_summary, is_generating_summary } = useSelector((state) => state.summaries);

  const ITEMS_PER_PAGE = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [current_summaries, setCurrentSummaries] = useState([]);

  const handleDropdownChange = (event) => {
    const selectedPage = Number(event.target.value);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {

    // if (is_error)
    // {
    //     console.log(message);
    // }

    if (curr_student_id === null)
    {
        navigate( PATH_NAME.Dashboard);

        return;
    }

    dispatch( getSummariesByStudent( curr_student_id ) );

    return () => {
        dispatch( resetGetStudentSummariesState() );
    };
  }, [navigate, curr_student_id, dispatch]);

  useEffect(() => {

    if (is_loaded_student_summaries && student_summaries)
    {
      setTotalItems(student_summaries.length);
      setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));

      setCurrentSummaries(student_summaries.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
      ));

      setCanRenderTable(true);
    }
  }, [is_loaded_student_summaries, student_summaries, currentPage, totalItems]);

  useEffect(() => {

    if (output_summary)
    {
      return () => {
        dispatch( resetGenerateSummary() );
      };
    }
  }, [output_summary, dispatch]);

  const go_to_update_summary = (shareable_id) => () => {

    navigate( PATH_NAME.Admin_Update_Summary + "/" + shareable_id + PATH_NAME.Edit_Mode );

  };

  const handleGenerateSummary = () => {
  // const handleGenerateSummary = (selected_reports) => {

    // if (selected_reports.length === 0)
    // {
    //   toast.error("Please select at least one report to generate a summary.");

    //   return;
    // }

    // console.log("Selected reports:", selected_reports);

    // const input_comments = [];
    // const input_reports = [];

    // selected_reports.forEach((report) => {
    //   // input_comments.push(report.weekly_comment);
    //   input_reports.push(report);
    // });

    // console.log("StudentReportsTable.js >> handleGenerateSummary :: input_comments = ", input_comments);
    // console.log("StudentReportsTable.js >> handleGenerateSummary :: input_reports = ", input_reports);
    
    // These reports should all have the same student
    // const summary_data = { student: input_reports[0].student, reports: input_reports };

    // Test
    // dispatch( getTranslatedSummary(input_comments) );
    // Don't forget to reset later

    // console.log("StudentSummariesTable.js >> handleGenerateSummary :: student_id = ", student_id);

    const input_data = { student_id: student_id };

    dispatch( generateSummary(input_data) );
  };

  if (is_generating_summary)
  {
    return <Spinner message={ UI_STRING_LOADING_ALL.Generating_Summary }/>;
  }

  return (
    
    !canRenderTable ? <Spinner /> :
      (
      <>
      <div className="p-4 tracking-wider">
        <div className="flex items-center mb-8 ml-10">
          <div className="text-white font-bold text-xl">Go to Page &nbsp;</div>
          <div>
            <select className="border rounded p-2" onChange={handleDropdownChange} value={currentPage}>
              {Array.from({ length: totalPages }, (_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="px-8 min-w-full table-auto rounded-3xl border border-separate border-tools-table-outline border-white border-4 w-full border-spacing-y-3">
            <thead className="bg-transparent text-xl">
              <tr>
                <th className="px-6 py-3 text-left font-extrabold text-white">Date Generated</th>
                <th className="px-6 py-3 text-left font-extrabold text-white flex items-center">
                  Public Link <FaLink className="ml-2" />
                </th>
                <th className="px-6 py-3 text-left font-extrabold text-white">Prepare Summary</th>
              </tr>
            </thead>

            <tbody className="bg-transparent text-xl">

              {current_summaries.length > 0 && current_summaries.map((summary, index) => (

                <tr key={index}
                  className={`pl-4 ${ (summary.status.includes(" - Signed") && summary.admin_signature) ? "bg-white" : "bg-rose-100"}`}
                >

                  <td className="px-6 py-4 whitespace-nowrap rounded-l-xl">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{getDate_MMDDYYYY_HKT( summary.createdAt )}</div>
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <Link to={PATH_NAME.Parent_View_Summary + "/" + summary.shareable_id} target="_blank" rel="noopener noreferrer" >
                        <button className="btn_item"><ImNewTab /></button>
                        </Link>
                        
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap rounded-r-xl">
                    <div className="flex items-center">
                      <div className="ml-4">
                        {/* <Link to="/sample_summary/66b643d0e1f5412fbbbf0168" target="_blank" rel="noopener noreferrer"> */}
                        <button className="btn_item" onClick={go_to_update_summary( summary.shareable_id )}><FaTools /></button>
                        {/* </Link> */}
                        
                      </div>
                    </div>
                  </td>
                </tr>
              ))}

              <tr className="my-12">
                <th colSpan="4" className="px-6 py-6 text-left font-medium text-white">
                <div className="text-white text-xl">
                  {totalItems === 0 ? (
                    "No entries to show"
                    ) : totalItems === 1 ? (
                    `Showing: 1 of 1 entry`
                    ) : (
                    `Showing: ${1 + (currentPage - 1) * ITEMS_PER_PAGE} to ${Math.min(currentPage * ITEMS_PER_PAGE, totalItems)} of ${totalItems} entries`
                  )}
                </div>
                </th>
              </tr>

            </tbody>
          </table>

        </div>

        <div className="flex justify-center mt-16 mb-6">
            <button className="btn btn_block w-5/6" onClick={() => handleGenerateSummary(  )}>
              <RiAiGenerate />&nbsp;Generate Summary
            </button>
        </div>

        <div className="flex justify-center mb-12 mx-4">
          {
            is_generating_summary ?
                <div className="text-gray-700 text-lg font-bold text-center">
                  Generating summary. Please wait...
                </div>
                :
                <></>
          }
          {/* {
            is_generated_summary && output_summary ?
                <div className="text-gray-700 text-lg font-bold text-center">
                  output_summary = {output_summary.output_summary}
                </div>
                :
                <></>
          } */}
          {/* {
            is_generated_summary && output_summary ?
              <Link to="/sample_summary/66b643d0e1f5412fbbbf0168" target="_blank" rel="noopener noreferrer">
                <div className="text-gray-700 text-xl font-bold text-center">
                  <span className="flex items-center">Click here to view the summary &nbsp; <ImNewTab /></span>
                </div>
                <div className="text-gray-100 text-lg font-medium text-center my-4 mx-4">
                  (Or find it under Parents tab)
                </div>
              </Link>
              :
              <></>
          } */}
        </div>

      </div>
      
      </>
      )
  );
};

export default StudentSummariesTable;
