import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PATH_NAME } from "../app/constants";

const Dashboard = () => {

    const navigate = useNavigate();

    const { user } = useSelector(state => state.auth);

    useEffect(() => {

        if (user)
        {
            if (user.role === "manager")
            {
                // Go to admin dashboard for now
                navigate( PATH_NAME.Admin_Dashboard );
            }
            else if (user.role === "admin")
            {
                navigate( PATH_NAME.Admin_Dashboard );
            }
            else if (user.role === "teacher")
            {
                // console.log("Dashboard.js >> User is a teacher. Redirecting to teacher dashboard...");

                navigate( PATH_NAME.Teacher_Dashboard );
            }
        }
    }, [user, navigate]);

    return (
        <>
        </>
    );
}

export default Dashboard;