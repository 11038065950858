import axios from "axios";
import { MAIN_URL } from "../../app/config";

const API_URL = MAIN_URL + "/api/recurring_classes/"

const addRecurringClass = async (class_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.post(API_URL, class_data, config);
    return response.data;
}

const getRecurringClasses = async token => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config);
    return response.data;
}

const recurringClassService = { getRecurringClasses, addRecurringClass };

export default recurringClassService;