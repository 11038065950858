import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import reportService from "./reportService";

const initialState_studentReports = {
    student_reports: [],
    is_loading_student_reports: false,
    is_loaded_student_reports: false,
    is_error_loading_student_reports: false,
    student_reports_message: "",
};

// Incomplete reports by all teachers
const initialState_pendingReports = {
    pending_reports: [],
    is_loading_pending_reports: false,
    is_loaded_pending_reports: false,
    is_error_loading_pending_reports: false,
    pending_reports_message: "",
};

// Incomplete reports by a specific teacher
const initialState_incompleteReports = {
    incomplete_reports: [],
    is_loading_incomplete_reports: false,
    is_loaded_incomplete_reports: false,
    is_error_loading_incomplete_reports: false,
    message_for_loading_incomplete_reports: "",
};

const initialState_fetchReport = {
    fetched_report: {},
    fetch_status: "idle",
    fetch_error: null,
};

const initialState_generateBlankReport = {
    is_error_generating_blank_report: false,
    is_generated_blank_report: false,
    is_generating_blank_report: false,
    message_for_generating_blank_report: ""
};

const initialState_updateReport = {
    is_updating_report: false,
    is_error_updating_report: false,
    is_updated_report: false,
    message_for_updating_report: "",
};

const initialState = {
    ...initialState_studentReports,
    ...initialState_pendingReports,
    ...initialState_incompleteReports,
    ...initialState_fetchReport,
    ...initialState_generateBlankReport,
    ...initialState_updateReport,
};

export const createReport = createAsyncThunk(
    "reports/create",
    async (report_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await reportService.createReport(report_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const generateBlankReport = createAsyncThunk(
    "reports/generate_blank",
    async (report_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            // console.log("reportSlice.js >> generateBlankReport :: report_data: ", report_data);

            return await reportService.generateBlankReport(report_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getReports = createAsyncThunk(
    "reports/get_all",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await reportService.getReports(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getPendingReportsByTeacher = createAsyncThunk(
    "reports/get_pending_by_teacher",
    async (teacher_username, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await reportService.getPendingReportsByTeacher(teacher_username, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getAllPendingReports = createAsyncThunk(
    "reports/get_all_pending",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            
            // console.log("reportSlice.js >> getAllPendingReports :: token = ", token);
            return await reportService.getAllPendingReports(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getReportsByStudent = createAsyncThunk(
    "reports/get_by_student",
    async (student_id, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await reportService.getReportsByStudent(student_id, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const fetchReportById = createAsyncThunk(
    "reports/fetch_by_id",
    async (report_id, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await reportService.fetchReportById(report_id, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const updateReport = createAsyncThunk(
    "reports/update",
    async (report_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            // console.log("reportSlice.js >> updateReport :: report_data: ", report_data);

            return await reportService.updateReport(report_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const deleteReport = createAsyncThunk(
    "reports/delete",
    async (id, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await reportService.deleteReport(id, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const reportSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        resetReports: state => initialState,
        resetGetStudentReportsState: state => initialState_studentReports,
        resetGetPendingReportsState: state => initialState_pendingReports,
        resetGetIncompleteReportsState: state => initialState_incompleteReports,
        resetFetchReportState: state => initialState_fetchReport,
        resetGenerateBlankReportState: state => initialState_generateBlankReport,
        resetUpdateReportState: state => initialState_updateReport,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReports.pending, (state) => {
                state.is_loading = true;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(getReports.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_success = true;
                state.reports = action.payload;
            })
            .addCase(getReports.rejected, (state, action) => {
                state.is_loading = false;
                state.is_error = true;
                state.message = action.payload;
            })
            .addCase(getPendingReportsByTeacher.pending, (state) => {
                state.is_loading_incomplete_reports = true;
                state.is_loaded_incomplete_reports = false;
                state.is_error_loading_incomplete_reports = false;
            })
            .addCase(getPendingReportsByTeacher.fulfilled, (state, action) => {
                state.is_loading_incomplete_reports = false;
                state.is_loaded_incomplete_reports = true;
                state.is_error_loading_incomplete_reports = false;
                state.incomplete_reports = action.payload;
            })
            .addCase(getPendingReportsByTeacher.rejected, (state, action) => {
                state.is_loading_incomplete_reports = false;
                state.is_loaded_incomplete_reports = false;
                state.is_error_loading_incomplete_reports = true;
                state.message_for_loading_incomplete_reports = action.payload;
            })
            .addCase(getAllPendingReports.pending, (state) => {
                state.is_loading_pending_reports = true;
                state.is_error_loading_pending_reports = false;
                state.is_loaded_pending_reports = false;
            })
            .addCase(getAllPendingReports.fulfilled, (state, action) => {
                state.is_loading_pending_reports = false;
                state.is_loaded_pending_reports = true;
                state.is_error_loading_pending_reports = false;
                state.pending_reports = action.payload;
            })
            .addCase(getAllPendingReports.rejected, (state, action) => {
                state.is_loading_pending_reports = false;
                state.is_error_loading_pending_reports = true;
                state.is_loaded_pending_reports = false;
                state.pending_reports_message = action.payload;
            })
            .addCase(getReportsByStudent.pending, (state) => {
                state.is_loading_student_reports = true;
                state.is_error_loading_student_reports = false;
                state.is_loaded_student_reports = false;
            })
            .addCase(getReportsByStudent.fulfilled, (state, action) => {
                state.is_loading_student_reports = false;
                state.is_loaded_student_reports = true;
                state.is_error_loading_student_reports = false;
                state.student_reports = action.payload;
            })
            .addCase(getReportsByStudent.rejected, (state, action) => {
                state.is_loading_student_reports = false;
                state.is_error_loading_student_reports = true;
                state.is_loaded_student_reports = false;
                state.message = action.payload;
            })
            .addCase(fetchReportById.pending, (state) => {
                state.fetch_status = "loading";
            })
            .addCase(fetchReportById.fulfilled, (state, action) => {
                state.fetch_status = "succeeded";
                state.fetched_report = action.payload;
            })
            .addCase(fetchReportById.rejected, (state, action) => {
                state.fetch_status = "failed";
                state.fetch_error = action.error.message;
            })
            .addCase(generateBlankReport.pending, (state) => {
                state.is_generating_blank_report = true;
                state.is_error_generating_blank_report = false;
                state.is_generated_blank_report = false;
            })
            .addCase(generateBlankReport.fulfilled, (state, action) => {
                state.is_generating_blank_report = false;
                state.is_generated_blank_report = true;
                state.is_error_generating_blank_report = false;
            })
            .addCase(generateBlankReport.rejected, (state, action) => {
                state.is_generating_blank_report = false;
                state.is_error_generating_blank_report = true;
                state.is_generated_blank_report = false;
                state.message_for_generating_blank_report = action.payload;
            })
            .addCase(updateReport.pending, (state) => {
                state.is_updating_report = true;
                state.is_updated_report = false;
                state.is_error_updating_report = false;
            })
            .addCase(updateReport.fulfilled, (state, action) => {
                state.is_updating_report = false;
                state.is_updated_report = true;
                state.is_error_updating_report = false;
            })
            .addCase(updateReport.rejected, (state, action) => {
                state.is_updating_report = false;
                state.is_updated_report = false;
                state.is_error_updating_report = true;
                state.message_for_updating_report = action.payload;
            })
            .addCase(deleteReport.pending, (state) => {
                state.is_loading = true;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(deleteReport.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_success = true;
                state.reports = state.reports.filter(report => report._id !== action.payload.id);
            })
            .addCase(deleteReport.rejected, (state, action) => {
                state.is_loading = false;
                state.is_error = true;
                state.message = action.payload;
            });
    }
});

export const {
                resetReports,
                resetGetStudentReportsState,
                resetGetPendingReportsState,
                resetGetIncompleteReportsState,
                resetFetchReportState,
                resetGenerateBlankReportState,
                resetUpdateReportState,

            } = reportSlice.actions;
export default reportSlice.reducer;