import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import recurringClassService from "./recurringClassService";

const initialState = {
    new_class: null,
    recurring_classes: [],
    is_error: false,
    is_success: false,
    is_loading: false,
    is_adding: false,
    is_added_class: false,
    is_reading_classes: false,
    message: ""
};

export const addRecurringClass = createAsyncThunk(
    "recurring_classes/add",
    async (class_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await recurringClassService.addRecurringClass(class_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getRecurringClasses = createAsyncThunk(
    "recurring_classes/get_all",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await recurringClassService.getRecurringClasses(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const recurringClassSlice = createSlice({
    name: "recurring_classes",
    initialState,
    reducers: {
        reset: state => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addRecurringClass.pending, state => {
                state.is_adding = true;
                state.is_error = false;
                state.is_added_class = false;
                //state.message = "";
            })
            .addCase(addRecurringClass.fulfilled, (state, action) => {
                state.is_adding = false;
                state.is_added_class = true;
                state.new_class = action.payload;
            })
            .addCase(addRecurringClass.rejected, (state, action) => {
                state.is_adding = false;
                state.is_error = true;
                state.is_added_classes = false;
                state.message = action.payload;
            })
            .addCase(getRecurringClasses.pending, (state) => {
                state.is_loading = true;
                state.is_reading_classes = true;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(getRecurringClasses.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_reading_classes = false;
                state.is_success = true;
                state.recurring_classes = action.payload;
            })
            .addCase(getRecurringClasses.rejected, (state, action) => {
                state.is_loading = false;
                state.is_reading_classes = false;
                state.is_error = true;
                state.message = action.payload;
            })
    }
});

export const { reset } = recurringClassSlice.actions;
export default recurringClassSlice.reducer;