import { Link } from "react-router-dom";
import { PATH_NAME } from "../../app/constants";

const FooterAdmin = () => {

    return (
        <footer className="bg-gray-200 py-12 mt-8 shadow-md top-full sticky">
            <Link to={ PATH_NAME.Admin_Dashboard }>
                <p className="text-gray-500">HOTS System - Admin Corner</p>
            </Link>
        </footer>
    );

}

export default FooterAdmin;