import React from "react";
import { COMMENT_AREA_ROWS } from "../../../app/constants";

const ReportFormSection = ({
    type = "radio", // default type is radio, but it can be "textarea", "checkbox", etc.
    label,
    name,
    value,
    options = [],
    on_change,
    is_in_edit_mode
}) => {

  return (
    <div className="report_form_section">
      <label className="text-gray-700 font-bold text-xl block mb-2 text-left">
        {label}
      </label>

      {type === "radio" && (
        <>
        <div className="my-6 ml-2">
          {options.map((option) => (
            <div key={option.value} className="flex items-center my-4">
              <input
                type="radio"
                name={name}
                value={option.value}
                checked={value === option.value}
                onChange={on_change}
                className="mx-8"
                style={{
                  transform: "scale(2)", // Scale radio button size
                  WebkitTransform: "scale(2)", // Safari-specific scaling
                }}
                disabled={!is_in_edit_mode}
              />
              <label className="text-white font-medium text-lg flex items-center">
                {option.label}
              </label>
            </div>
          ))}
        </div>
        </>
      )}
      
      {type === "text" && (
        <div className="form_group">
        <input
          type="text"
          name={name}
          value={value}
          onChange={on_change}
          className="form_control"
          disabled={!is_in_edit_mode}
        />
        </div>
      )}

      {type === "textarea" && (
        <div className="form_group">
        <textarea
          name={name}
          value={value}
          onChange={on_change}
          className="form_control"
          rows={COMMENT_AREA_ROWS}
          disabled={!is_in_edit_mode}
        />
        </div>
      )}

      {type === "checkbox" && (
        <div className="flex items-center my-4">
          <input
            type="checkbox"
            name={name}
            checked={!!value}
            onChange={on_change}
            className="mx-8"
            style={{
              transform: "scale(2)", // Scale checkbox size
              WebkitTransform: "scale(2)", // Safari-specific scaling
            }}
            disabled={!is_in_edit_mode}
          />
          <label className="text-white font-medium text-lg flex items-center">
            {label}
          </label>
        </div>
      )}
    </div>
  );
};

export default ReportFormSection;