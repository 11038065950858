import React, { useState } from "react";

const ImageViewer = ({ photo_urls }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (url) => {
    setSelectedImage(url);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      {photo_urls && photo_urls.length > 0 && (
        <>

        <div className="image_viewer my-10">
            {photo_urls && photo_urls.map((url, index) => (
            <img
                key={index}
                src={url}
                alt={`Uploaded file ${index + 1}`}
                className="uploaded_image"
                // style={{ width: "100px", height: "100px", objectFit: "cover", marginRight: "10px", marginBottom: "10px", borderRadius: "5px", cursor: "pointer" }}
                onClick={() => handleImageClick(url)}
            />
            ))}
        </div>

        {selectedImage && (
        <div className="image_modal_overlay" onClick={closeModal}>
            <div className="image_modal_content" onClick={(e) => e.stopPropagation()}>
            <img
                src={selectedImage}
                alt="Full size"
                style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
            <button className="image_close_button" onClick={closeModal}>Close</button>
            </div>
        </div>
        )}
        </>
      )}
    </>
  );
};

export default ImageViewer;
