import React from "react";
import ReportFormSection from "./ReportFormSection";
import { 
        FREQUENCIES,
        COMMENT_AREA_ROWS } from "../../../app/constants";
import {
        UI_STRING_REPORT_YELLOW,
        UI_STRING_REPORT_ALL, } from "../../../app/strings";

const UpdateReportFormYellow = ({ formData, handleContentChange, is_in_edit_mode }) => {
  return (
    <div>
    
    <ReportFormSection
        type="radio"
        label={`1. ${UI_STRING_REPORT_YELLOW.Group_1}`}
        name="_1_"
        value={formData.content._1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <hr className="mt-8 mb-8 thin" />

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`2. ${UI_STRING_REPORT_YELLOW.Group_2}`}
    </div>

    <ReportFormSection
        type="radio"
        label={`2.1 ${UI_STRING_REPORT_YELLOW.Group_2_Metric_1}`}
        name="_2_1_"
        value={formData.content._2_1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`2.2 ${UI_STRING_REPORT_YELLOW.Group_2_Metric_2}`}
        name="_2_2_"
        value={formData.content._2_2_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`2.3 ${UI_STRING_REPORT_YELLOW.Group_2_Metric_3}`}
        name="_2_3_"
        value={formData.content._2_3_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`2.4 ${UI_STRING_REPORT_YELLOW.Group_2_Metric_4}`}
        name="_2_4_"
        value={formData.content._2_4_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`2.5 ${UI_STRING_REPORT_YELLOW.Group_2_Metric_5}`}
        name="_2_5_"
        value={formData.content._2_5_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`2.6 ${UI_STRING_REPORT_YELLOW.Group_2_Metric_6}`}
        name="_2_6_"
        value={formData.content._2_6_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <hr className="mt-8 mb-8 thin" />

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`3. ${UI_STRING_REPORT_YELLOW.Group_3}`}
    </div>

    <ReportFormSection
        type="radio"
        label={`3.1 ${UI_STRING_REPORT_YELLOW.Group_3_Metric_1}`}
        name="_3_1_"
        value={formData.content._3_1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`3.2 ${UI_STRING_REPORT_YELLOW.Group_3_Metric_2}`}
        name="_3_2_"
        value={formData.content._3_2_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <hr className="mt-8 mb-8 thin" />
    
    <ReportFormSection
        type="radio"
        label={`4. ${UI_STRING_REPORT_YELLOW.Group_4}`}
        name="_4_"
        value={formData.content._4_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`5. ${UI_STRING_REPORT_YELLOW.Group_5}`}
        name="_5_"
        value={formData.content._5_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <hr className="mt-8 mb-8 thin" />

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`6. ${UI_STRING_REPORT_YELLOW.Group_6}`}
    </div>

    <ReportFormSection
        type="radio"
        label={`6.1 ${UI_STRING_REPORT_YELLOW.Group_6_Metric_1}`}
        name="_6_1_"
        value={formData.content._6_1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`6.2 ${UI_STRING_REPORT_YELLOW.Group_6_Metric_2}`}
        name="_6_2_"
        value={formData.content._6_2_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`6.3 ${UI_STRING_REPORT_YELLOW.Group_6_Metric_3}`}
        name="_6_3_"
        value={formData.content._6_3_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`6.4 ${UI_STRING_REPORT_YELLOW.Group_6_Metric_4}`}
        name="_6_4_"
        value={formData.content._6_4_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`6.5 ${UI_STRING_REPORT_YELLOW.Group_6_Metric_5}`}
        name="_6_5_"
        value={formData.content._6_5_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <hr className="mt-8 mb-8 thin" />

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`7. ${UI_STRING_REPORT_YELLOW.Group_7}`}
    </div>

    <ReportFormSection
        type="radio"
        label={`7.1 ${UI_STRING_REPORT_YELLOW.Group_7_Metric_1}`}
        name="_7_1_"
        value={formData.content._7_1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`7.2 ${UI_STRING_REPORT_YELLOW.Group_7_Metric_2}`}
        name="_7_2_"
        value={formData.content._7_2_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`7.3 ${UI_STRING_REPORT_YELLOW.Group_7_Metric_3}`}
        name="_7_3_"
        value={formData.content._7_3_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <hr className="mt-8 mb-8 thin" />

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`8. ${UI_STRING_REPORT_YELLOW.Group_8}`}
    </div>

    <ReportFormSection
        type="radio"
        label={`8.1 ${UI_STRING_REPORT_YELLOW.Group_8_Metric_1}`}
        name="_8_1_"
        value={formData.content._8_1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`8.2 ${UI_STRING_REPORT_YELLOW.Group_8_Metric_2}`}
        name="_8_2_"
        value={formData.content._8_2_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`8.3 ${UI_STRING_REPORT_YELLOW.Group_8_Metric_3}`}
        name="_8_3_"
        value={formData.content._8_3_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`8.4 ${UI_STRING_REPORT_YELLOW.Group_8_Metric_4}`}
        name="_8_4_"
        value={formData.content._8_4_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`8.5 ${UI_STRING_REPORT_YELLOW.Group_8_Metric_5}`}
        name="_8_5_"
        value={formData.content._8_5_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`8.6 ${UI_STRING_REPORT_YELLOW.Group_8_Metric_6}`}
        name="_8_6_"
        value={formData.content._8_6_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`8.7 ${UI_STRING_REPORT_YELLOW.Group_8_Metric_7}`}
        name="_8_7_"
        value={formData.content._8_7_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`8.8 ${UI_STRING_REPORT_YELLOW.Group_8_Metric_8}`}
        name="_8_8_"
        value={formData.content._8_8_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`8.9 ${UI_STRING_REPORT_YELLOW.Group_8_Metric_9}`}
        name="_8_9_"
        value={formData.content._8_9_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <ReportFormSection
        type="radio"
        label={`8.10 ${UI_STRING_REPORT_YELLOW.Group_8_Metric_10}`}
        name="_8_10_"
        value={formData.content._8_10_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Textarea: Weekly Comment Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_ALL.Weekly_Comment}
        name="weekly_comment"
        value={formData.content.weekly_comment}
        type="textarea"
        rows={COMMENT_AREA_ROWS} // Set the number of rows for textarea
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />
    
    </div>
  );
};

export default UpdateReportFormYellow;