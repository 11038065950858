import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaMicrophone, FaStop, FaUpload, FaTrash } from "react-icons/fa";
import { uploadVoiceMessages, resetUploadVoiceMessages } from "../../features/gcsUploads/gcsUploadSlice";
import { UI_STRING_REPORT_ALL } from "../../app/strings";

const RecordAndUploadVoiceComponent = ({ onUploadComplete }) => {

    const dispatch = useDispatch();

    const { new_voice_message_urls,
        is_uploaded_voice_messages,
        is_uploading_voice_messages,
        upload_voice_messages_progress,
        is_error_uploading_voice_messages,
    } = useSelector(state => state.media_uploads);

    const [isRecording, setIsRecording] = useState(false);
    const [audioBlobs, setAudioBlobs] = useState([]); // Array to store multiple recordings
    const [mediaRecorder, setMediaRecorder] = useState(null);

    useEffect(() => {

        if (is_uploaded_voice_messages && new_voice_message_urls && new_voice_message_urls.length > 0)
        {
            // console.log("RecordAndUploadVoiceComponent.js >> useEffect :: new_voice_message_urls: ", new_voice_message_urls);

            onUploadComplete(new_voice_message_urls);

            // return () => {

                // console.log("RecordAndUploadVoiceComponent.js >> useEffect :: dispatching resetUploadVoiceMessages");

                dispatch( resetUploadVoiceMessages() );
            // };
        }

        // Don't reset media upload state here, because we need to keep the URL for the report update

    }, [is_uploaded_voice_messages, new_voice_message_urls, dispatch, onUploadComplete]);

    useEffect(() => {

        if (is_error_uploading_voice_messages)
        {
            // console.log("RecordAndUploadVoiceComponent.js >> useEffect :: is_error_uploading_voice_messages = ", is_error_uploading_voice_messages);

            dispatch( resetUploadVoiceMessages() );
        }
    }, [is_error_uploading_voice_messages, dispatch]);

    const startRecording = () => {

        if (is_uploading_voice_messages) return;

        setIsRecording(true);

        const mediaStream = navigator.mediaDevices.getUserMedia({ audio: true });

        mediaStream.then(stream => {
            const recorder = new MediaRecorder(stream);
            setMediaRecorder(recorder);

            recorder.ondataavailable = (event) => {
                const newRecording = {
                    blob: event.data,
                    url: URL.createObjectURL(event.data),
                };

                setAudioBlobs((prev) => [...prev, newRecording]); // Add new recording to the array
            };

            recorder.start();
        });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.stop();
    };

//   const uploadFile = async (blob, index) => {
//     if (blob)
//     {
//         const audioFormData = new FormData();
//         audioFormData.append("voiceMessage", blob, `voice_message_${Date.now()}.webm`);

//         // console.log("RecordAndUploadVoiceComponent.js >> uploadFile :: audio_form_data = ", audio_form_data);

//         dispatch( uploadVoiceMessage(audioFormData) );
//     }
//   };

    const uploadBlobs = async () => {

        if (isRecording) return;

        if (audioBlobs.length === 0) return;

        // console.log("RecordAndUploadVoiceComponent.js >> uploadBlobs :: audioBlobs = ", audioBlobs);

        dispatch ( uploadVoiceMessages(audioBlobs) );
    };

    const removeRecording = (index) => {

        if (is_uploading_voice_messages) return;

        setAudioBlobs((prev) => prev.filter((_, i) => i !== index)); // Remove the recording at the specified index
    };

    return (
        <div className="flex flex-col items-center mt-2 mb-12">
            <div className="flex flex-col items-center">

                {audioBlobs.map((recording, index) => (

                <div key={index} className="mb-4 mt-8">
                    
                    <div className="flex items-center space-x-4"> {/* Flexbox to align side by side with spacing */}

                    <audio src={recording.url} controls />

                    {/* <span className="text-lg font-medium text-gray-600">Uploaded</span> */}

                    <button
                        type="button"
                        className="btn_item_gray"
                        onClick={() => removeRecording(index)}
                    >
                        <FaTrash />
                    </button>

                    </div>
                </div>
                ))}

            </div>

            <div className="flex items-center mt-12">
                {!isRecording && (
                <button type="button" onClick={startRecording} className="btn_recorder">
                    <FaMicrophone />
                </button>
                )}
                {isRecording && (
                <button type="button" onClick={stopRecording} className="btn_recorder">
                    <FaStop />
                </button>
                )}
            </div>

            <hr className="w-4/5 mt-12 mb-4" />

            <div className="flex items-center mt-4">
                <span className="text-lg font-medium text-gray-600">
                    {UI_STRING_REPORT_ALL.Voice_Messages_Upload_Prompt}
                </span>
            </div>

            <div className="flex items-center mb-2 mt-10">

                {!is_uploading_voice_messages && !is_uploaded_voice_messages && (

                    <button type="button" onClick={uploadBlobs} className="btn_item">
                        <FaUpload />
                    </button>
                )}

                {is_uploading_voice_messages && (
                    <div className="mt-6 text-xl text-white font-bold">
                        { <span>Uploading... { Math.round(upload_voice_messages_progress)}%</span> }
                    </div>
                )}
                {is_uploaded_voice_messages && (
                    <div className="mt-6 text-xl text-white font-bold">
                        <span>Upload complete</span>
                    </div>
                )}
            </div>

        </div>
    );
};

export default RecordAndUploadVoiceComponent;