import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderAdmin from "./HeaderAdmin";
import FooterAdmin from "./FooterAdmin";
import NotificationTable from "./NotificationTable";
import { can_access_page } from "../../app/user_utils";
import { PATH_NAME } from "../../app/constants";

const NotificationPage = () => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Admin_Notifications) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    return (
        <>
            <div className="basic_bg font-sans">
                
                <HeaderAdmin />

                <div className="dashboard_container">
                <section className="heading_alert">
                    Item(s) Requiring Attention
                    
                </section>
                <NotificationTable />
                </div>

                <FooterAdmin />
            </div>
        </>
    );
}

export default NotificationPage;