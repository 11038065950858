export const APP_NAME = "HOTS System";

export const UI_STRING_LOADING_ALL = {
    Adding_Lesson: "Adding the class",
    Generating_Reports: "Generating a blank report for each student",
    Saving_Report: "Saving the report",
    Loading_Incomplete_Reports_By_Teacher: "Loading reports to be completed",
    Loading_Lessons: "Loading the classes",
    Generating_Summary: "Generating the summary, please wait...",
};

export const UI_STRING_CLASS_SEARCH = {
    Search_Prompt: "Enter a past date to search",
};

export const UI_STRING_REPORT_ALL = {
    Update_Report: "Update Report",
    View_Report: "View Report",
    The_Teacher: "Teacher:",
    Report_Status: "Report Status:",
    Class_Level: "Class Level:",
    Week_Number: "Week Number:",
    The_Week: "Week",
    Completed: "Completed",
    Incomplete: "Not yet completed",
    Select_Week_Number: "Select Week Number:",
    Weekly_Comment: "Weekly Comment:",
    Voice_Messages_Record_Prompt: "Record Voice Messages for Student:",
    Voice_Messages_Upload_Prompt: "Upload Voice Messages:",
    Uploaded_Voice_Messages: "Uploaded Voice Messages for Student:",
    Photo_Upload_Prompt: "Upload Photos of Student in Class:",
    Uploaded_Photos: "Uploaded Photos of Student in Class:",
    Video_Upload_Prompt: "Upload Videos of Student in Class:",
    Uploaded_Videos: "Uploaded Videos of Student in Class:",
    Button_Save_Report: "Save Report",
    Button_Cancel: "Cancel",
    Toast_Error_Missing_Voice_Message: "Please record a voice message.",
    Toast_Error_Missing_Quiz_Photos: "Please upload photos of the student's quiz.",
    Toast_Error_Missing_Comment: "Please provide a weekly comment.",

};

export const UI_STRING_REPORT_YELLOW = {
    Group_1: "Separation:",
    Group_2: "Communication:",
    Group_2_Metric_1: "Responding during free play",
    Group_2_Metric_2: "Able to introduce him/herself",
    Group_2_Metric_3: "Willing to talk",
    Group_2_Metric_4: "Able to sing",
    Group_2_Metric_5: "Able to dance",
    Group_2_Metric_6: "Able to ask questions",
    Group_3: "Story Telling:",
    Group_3_Metric_1: "Understands during story time",
    Group_3_Metric_2: "Responds during story time",
    Group_4: "Fine Motor Skills (FMS):",
    Group_5: "Gross Motor Skills (GMS):",
    Group_6: "Academic:",
    Group_6_Metric_1: "Colours",
    Group_6_Metric_2: "Letters",
    Group_6_Metric_3: "Shapes",
    Group_6_Metric_4: "Vocabulary",
    Group_6_Metric_5: "HOTS Activities",
    Group_7: "Creativity:",
    Group_7_Metric_1: "Able to draw / paint",
    Group_7_Metric_2: "Able to come up with ideas",
    Group_7_Metric_3: "Able to express his/her ideas",
    Group_8: "Attitude:",
    Group_8_Metric_1: "Willing to share",
    Group_8_Metric_2: "Show compassion",
    Group_8_Metric_3: "Able to focus",
    Group_8_Metric_4: "Able to say \"thank you\"",
    Group_8_Metric_5: "Able to say \"please\"",
    Group_8_Metric_6: "Able to tidy up",
    Group_8_Metric_7: "Happy",
    Group_8_Metric_8: "Able to follow teacher's instructions",
    Group_8_Metric_9: "Grouping activities",
    Group_8_Metric_10: "Able to wait",
};

export const UI_STRING_REPORT_RED = {
    Key_Object: "Key Object:",
    Key_Letter: "Key Letter:",
    Metric_1: "Phonics Sound A-Z",
    Metric_2: "Communication",
    Metric_3: "Listening",
    Metric_4: "Speaking",
    Metric_5: "Reading",
    Metric_6: "Letter Recognition A-Z", 
    Metric_7: "Tracing paper",
    Metric_8: "Story Making / Thinking",
    Metric_9: "Interest to learn",
    Metric_10: "Be a coach (senior teaches junior)",
    Metric_11: "Attention span / Discipline",
    Can_Advance: "Able to go to Pre-Blue level?",
    Toast_Error_Missing_Key_Object: "Please provide a key object.",
    Toast_Error_Missing_Key_Letter: "Please provide a key letter.",
};