import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../features/auth/authSlice";
import settingReducer from "../features/settings/settingSlice";
import reportReducer from "../features/reports/reportSlice";
import adminAlertReducer from "../features/adminAlerts/adminAlertSlice";
import studentReducer from "../features/students/studentSlice";
import teacherReducer from "../features/teachers/teacherSlice";
import recurringClassReducer from "../features/recurringClasses/recurringClassSlice";
import lessonReducer from "../features/lessons/lessonSlice";
import gcsUploadReducer from "../features/gcsUploads/gcsUploadSlice";
import fileReducer from "../features/files/fileSlice";
import summaryReducer from "../features/summaries/summarySlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    settings: settingReducer,
    reports: reportReducer,
    admin_alerts: adminAlertReducer,
    students: studentReducer,
    teachers: teacherReducer,
    recurring_classes: recurringClassReducer,
    lessons: lessonReducer,
    media_uploads: gcsUploadReducer,
    media_downloads: fileReducer,
    summaries: summaryReducer,
  },
});