import { logout, resetAuth } from "../features/auth/authSlice";
import { resetTeachers } from "../features/teachers/teacherSlice";
import { resetStudents } from "../features/students/studentSlice";

import { resetLessons } from "../features/lessons/lessonSlice";
import { resetReports } from "../features/reports/reportSlice";
import { resetSummaries } from "../features/summaries/summarySlice";

import { resetMediaUploads } from "../features/gcsUploads/gcsUploadSlice";
import { PATH_NAME } from "./constants";
import { resetSettings } from "../features/settings/settingSlice";

// May add to beforeunload and unload events too
export const run_logout = (dispatch, navigate) => {
  // Perform the necessary dispatch actions
  dispatch( logout() );
  dispatch( resetAuth() );
  dispatch( resetSettings() );
  dispatch( resetTeachers() );
  dispatch( resetStudents() );

  dispatch( resetLessons() );
  dispatch( resetReports() );
  dispatch( resetSummaries() );

  dispatch( resetMediaUploads() );
  // More resets or cleanups here

  // Navigate to the home or dashboard route
  navigate( PATH_NAME.Dashboard);
};

export const can_access_page = (user, path_name) => {
  if (!user) return false;

  if (user.role === "admin" || user.role === "manager")
  {
    // Should match up with Admin specific Routes in App.js
    if ( path_name === PATH_NAME.Admin_Dashboard
          || path_name === PATH_NAME.Admin_Notifications
          || path_name === PATH_NAME.Admin_Add_Lesson
          || path_name === PATH_NAME.Admin_Class_List
          || path_name === PATH_NAME.Admin_Day_Lessons
          || path_name === PATH_NAME.Admin_Student_List
          || path_name === PATH_NAME.Admin_Add_Student
          || path_name === PATH_NAME.Admin_Student_Reports
          || path_name === PATH_NAME.Admin_Parent_List
          || path_name === PATH_NAME.Admin_Student_Summaries
          || path_name === PATH_NAME.Admin_Update_Report
          || path_name === PATH_NAME.Admin_Update_Summary
        )
    {
      return true;
    }
  }
  else if (user.role === "teacher")
  {
    // console.log("user_utils.js >> Teacher accessing page: ", path_name);

    // Should match up with Teacher specific Routes in App.js
    if ( path_name === PATH_NAME.Teacher_Dashboard
          || path_name === PATH_NAME.Teacher_My_Students
          || path_name === PATH_NAME.Teacher_My_Student_Reports
          || path_name === PATH_NAME.Teacher_Update_Report
    )
    {
      // console.log("user_utils.js >> Teacher access granted");

      return true;
    }
  }
  else if (user.role === "parent")
  {
    // if (path_name === PATH_NAME.Parent_Dashboard)
    // {
    //   return true;
    // }
  }

  return false;
};
