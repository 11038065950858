import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaUpload } from "react-icons/fa";
import { uploadImages, uploadVideos, resetUploadImages, resetUploadVideos } from '../../features/gcsUploads/gcsUploadSlice';

const UploadMediaComponent = ({ fileType, onUploadComplete }) => {

    const inputFileType = fileType ? fileType : "image";

    const dispatch = useDispatch();

    const [selectedFiles, setSelectedFiles] = useState([]);
    const { new_image_urls, is_uploaded_images, is_uploading_images, upload_images_progress,
            new_video_urls, is_uploaded_videos, is_uploading_videos, upload_videos_progress,
        } = useSelector(state => state.media_uploads);

    useEffect(() => {
        if (inputFileType === "image")
        {
            if (is_uploaded_images && new_image_urls && new_image_urls.length > 0)
            {
                // console.log("UploadMediaComponents.js >> useEffect :: new_image_urls: ", new_image_urls);
                
                onUploadComplete(new_image_urls);

                dispatch( resetUploadImages() );
            }
        }

        // Don't reset media upload state here, because we need to keep the URL for the report update

    }, [inputFileType, is_uploaded_images, new_image_urls, dispatch, onUploadComplete]);

    useEffect(() => {
        if (inputFileType === "video")
        {
            if (is_uploaded_videos && new_video_urls && new_video_urls.length > 0)
            {
                // console.log("UploadMediaComponents.js >> useEffect :: new_video_urls: ", new_video_urls);
                
                onUploadComplete(new_video_urls);

                dispatch( resetUploadVideos() );
            }
        }

        // Don't reset media upload state here, because we need to keep the URL for the report update

    }, [inputFileType, is_uploaded_videos, new_video_urls, dispatch, onUploadComplete]);

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    const uploadFiles = async () => {
        if (selectedFiles.length === 0) return;

        if (is_uploading_images || is_uploading_videos) return;

        if (inputFileType === "image")
        {
            // console.log("UploadMediaComponent.js >> uploadFiles :: selectedImageFiles = ", selectedFiles);
            dispatch( uploadImages(selectedFiles) );
        }
        else if (inputFileType === "video")
        {
            // console.log("UploadMediaComponent.js >> uploadFiles :: selectedVideoFiles = ", selectedFiles);
            dispatch( uploadVideos(selectedFiles) );
        }
    };

    return (
        <div className="flex flex-col items-center mt-4 mb-12">
            <input className="mt-8 rounded-3xl border-4" type="file"
                    accept={inputFileType === "image" ? "image/*" : "video/*"}
                    multiple
                    onChange={handleFileChange} />
            
            { inputFileType === "image" && (
                <>
                { (!is_uploading_images) && (
                <>
                <button type="button" className="btn_item mt-6" onClick={ () => uploadFiles() } >
                    <FaUpload />
                </button>
                </>
                )
                }

                <div>
                    {is_uploading_images && (
                        <div className="mt-6 text-xl text-white font-bold">
                            {/* <span>Uploading files, please wait...</span> */}
                            { <span>Uploading... { Math.round(upload_images_progress)}%</span> }
                        </div>
                    )}
                    {is_uploaded_images && (
                        <div className="mt-6 text-xl text-white font-bold">
                            <span>Upload complete</span>
                        </div>
                    )}
                </div>
                </>
            )}

            { inputFileType === "video" && (
                <>
                { (!is_uploading_videos) && (
                <>
                <button type="button" className="btn_item mt-6" onClick={ () => uploadFiles() } >
                    <FaUpload />
                </button>
                </>
                )
                }

                <div>
                    {is_uploading_videos && (
                        <div className="mt-6 text-xl text-white font-bold">
                            {/* <span>Uploading files, please wait...</span> */}
                            { <span>Uploading... { Math.round(upload_videos_progress)}%</span> }
                        </div>
                    )}
                    {is_uploaded_videos && (
                        <div className="mt-6 text-xl text-white font-bold">
                            <span>Upload complete</span>
                        </div>
                    )}
                </div>
                </>
            )}
        </div>
    );
};

export default UploadMediaComponent;
