import axios from "axios";
import { MAIN_URL } from "../../app/config";

const API_URL = MAIN_URL + "/api/teachers/"

const getTeachersByName = async (teacher_name, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    const response = await axios.get(API_URL + "search?name=" + teacher_name, config);
    return response.data;
}


const teacherService = { getTeachersByName};

export default teacherService;