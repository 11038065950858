import axios from "axios";
import { MAIN_URL } from "../../app/config";

const API_URL = MAIN_URL + "/api/students/"

const addStudent = async (student_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.post(API_URL, student_data, config);
    return response.data;
}

const getStudents = async token => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config);
    return response.data;
}

// Add another function for filtering later

const getOneStudent = async (student_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL + "one/" + student_id, config);
    return response.data;
}

const updateOneStudent = async (student_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.put(API_URL + "one/" + student_data.student_id, student_data, config);
    return response.data;
}

const getStudentsByID = async (student_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("studentService.js >> getStudentsByID :: student_id = ", student_id);
    const response = await axios.get(API_URL + "search_by_id?id=" + student_id, config);
    return response.data;
}

const getStudentsByName = async (student_name, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("studentService.js >> getStudentsByName :: student_name = ", student_name);
    const response = await axios.get(API_URL + "search_by_name?name=" + student_name, config);
    return response.data;
}

const studentService = { addStudent, getStudents,
                    getOneStudent, updateOneStudent,
                    getStudentsByID, getStudentsByName, 
 };

export default studentService;