import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import lessonService from "./lessonService";

const initialState_AddLesson = {
    new_lesson: null,
    is_adding: false,
    is_added_lesson: false,
    is_error_adding_lesson: false,
    message_for_adding_lesson: "",
}

const initialState_FetchLessonsByDate = {
    lessons_on_date: [],
    is_fetching_lessons_by_date: false,
    is_fetched_lessons_by_date: false,
    is_error_fetching_lessons_by_date: false,
    message_for_fetching_lessons_by_date: "",
}

const initialState = {
    lessons: [],
    is_error: false,
    is_success: false,
    is_loading: false,

    is_reading_lessons: false,
    is_error_reading_lessons: false,
    is_success_reading_lessons: false,
    message: "",

    ...initialState_AddLesson,
    ...initialState_FetchLessonsByDate,
};

export const addLesson = createAsyncThunk(
    "lessons/add",
    async (lesson_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            // console.log("lessonSlice.js >> addLesson :: lesson_data: ", lesson_data);

            return await lessonService.addLesson(lesson_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getLessons = createAsyncThunk(
    "lessons/get_all",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await lessonService.getLesson(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getLessonsByDate = createAsyncThunk(
    "lessons/get_by_date",
    async (date_string, thunkAPI) => {
        try
        {
            // console.log("lessonSlice.js >> getLessonsByDate :: date_string = " + date_string);

            const token = thunkAPI.getState().auth.user.token;
            return await lessonService.getLessonsByDate(date_string, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const lessonSlice = createSlice({
    name: "lessons",
    initialState,
    reducers: {
        resetLessons: state => initialState,
        resetAddLesson: state => initialState_AddLesson,
        resetFetchLessonsByDate: state => initialState_FetchLessonsByDate,
    },
    extraReducers: (builder) => {
        builder
            .addCase(addLesson.pending, state => {
                state.is_adding_lesson = true;
                state.is_added_lesson = false;
                state.is_error_adding_lesson = false;
                state.message_for_adding_lesson = "";
            })
            .addCase(addLesson.fulfilled, (state, action) => {
                state.is_adding_lesson = false;
                state.is_added_lesson = true;
                state.is_error_adding_lesson = false;
                state.new_lesson = action.payload;
            })
            .addCase(addLesson.rejected, (state, action) => {
                state.is_adding_lesson = false;
                state.is_added_lessons = false;
                state.is_error_adding_lesson = true;
                state.message_for_adding_lesson = action.payload;
            })

            .addCase(getLessons.pending, (state) => {
                state.is_loading = true;
                state.is_reading_lessons = true;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(getLessons.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_reading_lessons = false;
                state.is_success = true;
                state.lessons = action.payload;
            })
            .addCase(getLessons.rejected, (state, action) => {
                state.is_loading = false;
                state.is_reading_lessons = false;
                state.is_error = true;
                state.message = action.payload;
            })

            .addCase(getLessonsByDate.pending, (state) => {
                state.is_fetching_lessons_by_date = true;
                state.is_fetched_lessons_by_date = false;
                state.is_error_fetching_lessons_by_date = false;
                state.message_for_fetching_lessons_by_date = "";
            })
            .addCase(getLessonsByDate.fulfilled, (state, action) => {
                state.is_fetching_lessons_by_date = false;
                state.is_fetched_lessons_by_date = true;
                state.is_error_fetching_lessons_by_date = false;
                state.lessons_on_date = action.payload;
            })
            .addCase(getLessonsByDate.rejected, (state, action) => {
                state.is_fetching_lessons_by_date = false;
                state.is_fetched_lessons_by_date = false;
                state.is_error_fetching_lessons_by_date = true;
                state.message_for_fetching_lessons_by_date = action.payload;
            })
    }
});

export const { resetLessons,
                resetAddLesson,
                resetFetchLessonsByDate
            } = lessonSlice.actions;
export default lessonSlice.reducer;