export const REFRESH_ALERT_MS = 300000; // 5 minutes
export const COMMENT_AREA_ROWS = 10;
export const TOAST_ERROR_MS = 10000; // 10 seconds

export const PATH_NAME = {
    Dashboard:                  "/",
    Login:                      "/login",
    Admin_Dashboard:            "/admin_dashboard",
    Admin_Notifications:        "/notifications",
    Admin_Add_Lesson:           "/add_lesson",
    Admin_Class_List:           "/class_list",
    Admin_Day_Lessons:          "/day",
    Admin_Student_List:         "/student_list",
    Admin_Add_Student:          "/add_student",
    Admin_Student_Reports:      "/student",
    Admin_Parent_List:          "/parent_list",
    Admin_Student_Summaries:    "/student_summary",
    Admin_Update_Report:        "/report",
    Admin_Update_Summary:       "/update_summary",
    Teacher_Dashboard:          "/teacher_dashboard",
    Teacher_My_Students:        "/my_students",
    Teacher_My_Student_Reports: "/my_student",
    Teacher_Update_Report:      "/update_report",
    Parent_View_Summary:        "/summary",
    Edit_Mode:                  "/edit_mode",
};

export const STUDENT_ID_PREFIX = "SYL";

export const CLASS_LEVEL = {
    Yellow: "Yellow",
    Pink: "Pink",
    Red: "Red",
    Pre_Blue: "Pre-Blue",
    Blue: "Blue",
    Green: "Green",
    HEEP: "HEEP",
    HE_L1: "HE L1",
    HE_L2: "HE L2",
    HI: "HI",
    Reading: "Reading",
}

export const CLASS_LEVELS = [
    { label: "Yellow",      value: "Yellow",     type: "Junior" },
    { label: "Pink",        value: "Pink",       type: "Junior" },
    { label: "Red",         value: "Red",        type: "Junior" },
    { label: "Pre-Blue",    value: "Pre-Blue",   type: "Junior" },
    { label: "Blue",        value: "Blue",       type: "Junior" },
    { label: "Green",       value: "Green",      type: "Junior" },
    { label: "HEEP",        value: "HEEP",       type: "Senior" },
    { label: "HE L1",       value: "HE L1",      type: "Senior" },
    { label: "HE L2",       value: "HE L2",      type: "Senior" },
    { label: "HI",          value: "HI",         type: "Senior" },
    { label: "Reading",     value: "Reading",    type: "Junior" },
];

export const DAY_OF_WEEK = {
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday",
};

export const DAY_OF_WEEK_NUM = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export const DAY_OF_WEEK_NUM_SHORT = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thur",
    "Fri",
    "Sat",
];

export const RATINGS = [
    { label: "5 = excellent", value: "5" },
    { label: "4", value: "4" },
    { label: "3", value: "3" },
    { label: "2", value: "2" },
    { label: "1", value: "1" },
];

export const FREQUENCIES = [
    { label: "5 = Yes; always; completely", value: "5" },
    { label: "4 = Yes; mostly; almost all", value: "4" },
    { label: "3 = Sometimes; partially", value: "3" },
    { label: "2 = No; rarely; not much", value: "2" },
    { label: "1 = No; never; not at all", value: "1" },
];

export const WEEK_NUMBERS_SENIOR = [
    { label: "Week 1", value: "1" },
    { label: "Week 2", value: "2" },
    { label: "Week 3", value: "3" },
    { label: "Week 4", value: "4" },
    { label: "Week 5", value: "5" },
    { label: "Week 6", value: "6" },
];

export const WEEK_NUMBERS_JUNIOR = [
    { label: "Week 1", value: "1" },
    { label: "Week 2", value: "2" },
    { label: "Week 3", value: "3" },
    { label: "Week 4", value: "4" },
];

export const TIME_SLOTS = [
    { label: "(A) 09:15 AM - 10:25 AM",     value: "1", start: "0915", end: "1025" },
    { label: "(A*) 09:15 AM - 11:00 AM",    value: "2", start: "0915", end: "1100" },
    { label: "(B) 10:35 AM - 11:45 AM",     value: "3", start: "1035", end: "1145" },
    { label: "(B*) 11:15 AM - 01:00 PM",    value: "4", start: "1115", end: "1300" },
    { label: "(C) 11:55 AM - 01:05 PM",     value: "5", start: "1155", end: "1305" },
    { label: "(D) 02:05 PM - 03:15 PM",     value: "6", start: "1405", end: "1515" },
    { label: "(E) 03:25 PM - 04:35 PM",     value: "7", start: "1525", end: "1635" },
    { label: "(F) 04:45 PM - 05:55 PM",     value: "8", start: "1645", end: "1755" },
    { label: "(F*) 06:05 PM - 07:15 PM",    value: "9", start: "1805", end: "1915" },
];

export const ROOMS = [
    { label: "Room 1", center: "SYL", shop: "68", value: "Room 01" },
    { label: "Room 2", center: "SYL", shop: "68", value: "Room 02" },
    { label: "Room 3", center: "SYL", shop: "68", value: "Room 03" },
    { label: "Room 4", center: "SYL", shop: "68", value: "Room 04" },
    { label: "Room 5", center: "SYL", shop: "68", value: "Room 05" },
    { label: "Room 7", center: "SYL", shop: "71", value: "Room 07" },
    { label: "Room 8", center: "SYL", shop: "71", value: "Room 08" },
    { label: "Room 9", center: "SYL", shop: "71", value: "Room 09" },
    { label: "Room 10", center: "SYL", shop: "71", value: "Room 10" },
    { label: "Room 11", center: "SYL", shop: "57", value: "Room 11" },
    { label: "Room 12", center: "SYL", shop: "57", value: "Room 12" },
    { label: "Room 13", center: "SYL", shop: "57", value: "Room 13" },
];