import axios from "axios";
import { MAIN_URL } from "../../app/config";

const API_URL = MAIN_URL + "/api/users/";

const register = async (user_data) => {
    const response = await axios.post(API_URL + "register", user_data);

    if (response.data)
    {
        localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
};

const registerTeacher = async (teacher_data) => {
    const response = await axios.post(API_URL + "register_teacher_user", teacher_data);

    // Return the newly added teacher as a user object
    return response.data;
};

const login = async (user_data) => {
    const response = await axios.post(API_URL + "login", user_data);

    // console.log("authService >> login :: response.data -- " + JSON.stringify(response.data));

    if (response.data)
    {
        localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
};

const getAllTeachers = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("authService >> getAllTeachers :: token -- " + token);
    const response = await axios.get(API_URL + "all_teachers", config);
    return response.data;
};

const checkToken = async (token) => {

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    
    // console.log("authService >> checkToken :: token -- " + token);

    const response = await axios.get(API_URL + "check_access", config);
    return response.data;
};

const logout = () => { localStorage.removeItem("user"); };

const authService = { register, registerTeacher, logout, login, getAllTeachers, checkToken };

export default authService;