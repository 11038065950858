// Optional: message
const Spinner = ({ message }) => {
    return (
        <div className="loading_spinner_container flex flex-col items-center" data-testid="spin-container">
            <div className="loading_spinner" data-testid="inner-spin-container"/>

            {
                message &&
                <div className="text-gray-200 text-3xl font-bold text-center mt-8">
                    {message}
                </div>
            }
        </div>
    );
};

export default Spinner;