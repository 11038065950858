import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import studentService from './studentService';

const initialState_addStudent = {
    new_student: null,
    is_adding_student: false,
    is_added_student: false,
    is_error_adding_student: false,
    message_adding_student: "",
};

const initialState_currentStudent = {
    current_student: null,
};

const initialState_fetchStudent = {
    is_fetching_student: false,
    is_fetched_student: false,
    is_error_fetching_student: false,
    message_fetching_student: ""
};

const initialState_updateStudent = {
    is_updating_student: false,
    is_updated_student: false,
    is_error_updating_student: false,
    message_updating_student: ""
};

const initialState = {
    ...initialState_addStudent,
    student: null,
    students: [],
    is_error: false,
    is_success: false,
    is_loading: false,
    is_reading_students: false,
    is_error_reading_students: false,
    is_success_reading_students: false,
    message: "",
    ...initialState_currentStudent,
    ...initialState_fetchStudent,
    ...initialState_updateStudent,
};

export const addStudent = createAsyncThunk(
    "students/add",
    async (student_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await studentService.addStudent(student_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getOneStudent = createAsyncThunk(
    "students/get_one",
    async (student_id, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await studentService.getOneStudent(student_id, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const updateOneStudent = createAsyncThunk(
    "students/update_one",
    async (student_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await studentService.updateOneStudent(student_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getStudents = createAsyncThunk(
    "students/get",
    async (filter, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await studentService.getStudents(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getStudentsByID = createAsyncThunk(
    "students/get_by_id",
    async (student_id, thunkAPI) => {
        try
        {
            // console.log("studentSlice.js >> getStudentsByID :: student_id: ", student_id);

            const token = thunkAPI.getState().auth.user.token;

            // console.log("studentSlice.js >> getStudentsByID :: token: ", token);

            return await studentService.getStudentsByID(student_id, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getStudentsByName = createAsyncThunk(
    "students/get_by_name",
    async (student_name, thunkAPI) => {
        try
        {
            // console.log("studentSlice.js >> getStudentsByName :: student_name: ", student_name);

            const token = thunkAPI.getState().auth.user.token;

            // console.log("studentSlice.js >> getStudentsByName :: token: ", token);

            return await studentService.getStudentsByName(student_name, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const studentSlice = createSlice({
    name: "students",
    initialState,
    reducers: {
        resetStudents: state => initialState,
        resetAddStudent: state => initialState_addStudent,
        resetCurrentStudent: state => initialState_currentStudent,
        resetFetchStudent: state => initialState_fetchStudent,
        resetUpdateStudent: state => initialState_updateStudent,
    },
    extraReducers: (builder) => {
        builder
            .addCase(addStudent.pending, state => {
                state.is_adding_student = true;
                state.is_added_student = false;
                state.is_error_adding_student = false;
                //state.message = "";
            })
            .addCase(addStudent.fulfilled, (state, action) => {
                state.is_adding_student = false;
                state.is_added_student = true;
                state.is_error_adding_student = false;
                // state.student_list.push(action.payload);
                state.new_student = action.payload;
            })
            .addCase(addStudent.rejected, (state, action) => {
                state.is_adding_student = false;
                state.is_error_adding_student = true;
                state.is_added_student = false;
                state.message_adding_student = action.payload;
            })

            .addCase(getOneStudent.pending, state => {
                state.is_fetching_student = true;
                state.is_fetched_student = false;
                state.is_error_fetching_student = false;
            })
            .addCase(getOneStudent.fulfilled, (state, action) => {
                state.is_fetching_student = false;
                state.is_fetched_student = true;
                state.is_error_fetching_student = false;
                state.current_student = action.payload;
            })
            .addCase(getOneStudent.rejected, (state, action) => {
                state.is_fetching_student = false;
                state.is_fetched_student = false;
                state.is_error_fetching_student = true;
                state.message_fetching_student = action.payload;
            })
            .addCase(updateOneStudent.pending, state => {
                state.is_updating_student = true;
                state.is_updated_student = false;
                state.is_error_updating_student = false;
            })
            .addCase(updateOneStudent.fulfilled, (state, action) => {
                state.is_updating_student = false;
                state.is_updated_student = true;
                state.is_error_updating_student = false;
                state.current_student = action.payload;
            })
            .addCase(updateOneStudent.rejected, (state, action) => {
                state.is_updating_student = false;
                state.is_updated_student = false;
                state.is_error_updating_student = true;
                state.message_updating_student = action.payload;
            })

            .addCase(getStudents.pending, (state) => {
                state.is_loading = true;
                state.is_reading_students = true;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(getStudents.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_reading_students = false;
                state.is_success = true;
                state.students = action.payload;
            })
            .addCase(getStudents.rejected, (state, action) => {
                state.is_loading = false;
                state.is_reading_students = false;
                state.is_error = true;
                state.message = action.payload;
            })
            .addCase(getStudentsByID.pending, (state) => {
                state.is_reading_students = true;
                state.is_success_reading_students = false;
                state.is_error_reading_students = false;
            })
            .addCase(getStudentsByID.fulfilled, (state, action) => {
                state.is_reading_students = false;
                state.is_success_reading_students = true;
                state.is_error_reading_students = false;
                state.students = action.payload;
            })
            .addCase(getStudentsByID.rejected, (state, action) => {
                state.is_reading_students = false;
                state.is_success_reading_students = false;
                state.is_error_reading_students = true;
                state.message = action.payload;
            })
            .addCase(getStudentsByName.pending, (state) => {
                state.is_reading_students = true;
                state.is_success_reading_students = false;
                state.is_error_reading_students = false;
            })
            .addCase(getStudentsByName.fulfilled, (state, action) => {
                state.is_reading_students = false;
                state.is_success_reading_students = true;
                state.is_error_reading_students = false;
                state.students = action.payload;
            })
            .addCase(getStudentsByName.rejected, (state, action) => {
                state.is_reading_students = false;
                state.is_success_reading_students = false;
                state.is_error_reading_students = true;
                state.message = action.payload;
            })
    }
});

export const { resetStudents, resetAddStudent,
                resetCurrentStudent, resetFetchStudent, resetUpdateStudent
 } = studentSlice.actions;
export default studentSlice.reducer;