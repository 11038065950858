import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getReportsByStudent, resetGetStudentReportsState } from "../../features/reports/reportSlice";
import Spinner from "../Spinner";
import { FaEye } from "react-icons/fa";
import { PATH_NAME } from "../../app/constants";

const MyStudentReportsTable = ({ student_id }) => {

  const curr_student_id = student_id ? student_id : null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [canRenderTable, setCanRenderTable] = useState(false);

  const { student_reports, is_loaded_student_reports } = useSelector((state) => state.reports);

  const ITEMS_PER_PAGE = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [current_reports, setCurrentReports] = useState([]);

  const handleDropdownChange = (event) => {
    const selectedPage = Number(event.target.value);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {

    // if (is_error)
    // {
    //     console.log(message);
    // }

    if (curr_student_id === null)
    {
        navigate( PATH_NAME.Dashboard );

        return;
    }

    dispatch( getReportsByStudent( curr_student_id ) );

    return () => {
        dispatch( resetGetStudentReportsState() );
    };
  }, [navigate, curr_student_id, dispatch]);

  useEffect(() => {

    if (is_loaded_student_reports && student_reports)
    {
      setTotalItems(student_reports.length);
      setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));

      setCurrentReports(student_reports.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
      ));

      setCanRenderTable(true);
    }
  }, [is_loaded_student_reports, student_reports, currentPage, totalItems]);

  const handleEditClick = (report_id, class_level) => {

    navigate( PATH_NAME.Teacher_Update_Report + "/" + class_level + "/" + report_id + PATH_NAME.Edit_Mode );
    // navigate(`/update_report/${class_level}/${report_id}`);
  };

  return (
    !canRenderTable ? <Spinner /> :
      (
      <>
      <div className="p-4 tracking-wider">
        <div className="flex items-center mb-8 ml-10">
          <div className="text-white font-bold text-xl">Go to Page &nbsp;</div>
          <div>
            <select className="border rounded p-2" onChange={handleDropdownChange} value={currentPage}>
              {Array.from({ length: totalPages }, (_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="px-8 min-w-full table-auto rounded-3xl border border-separate border-tools-table-outline border-white border-4 w-full border-spacing-y-3">
            <thead className="bg-transparent text-xl">
              <tr>
                <th className="px-6 py-3 text-left font-extrabold text-white uppercase">Date Created</th>
                <th className="px-6 py-3 text-left font-extrabold text-white uppercase">Class Level</th>
                <th className="px-6 py-3 text-left font-extrabold text-white uppercase">Week No.</th>
                <th className="px-6 py-3 text-left font-extrabold text-white uppercase">View / Edit</th>
              </tr>
            </thead>

            <tbody className="bg-transparent text-xl">

              {current_reports.length > 0 && current_reports.map((report, index) => (

                <tr key={index}
                    className={`pl-4 ${!report.is_completed ? "bg-rose-100" : "bg-white"}`}
                >

                  <td className="px-6 py-4 whitespace-nowrap font-medium rounded-l-xl">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{report.lesson.date_string}</div>
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap font-medium">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="font-medium text-hots-red">{report.lesson.class_level}</div>
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap font-medium">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="font-medium text-hots-red">{report.week_number}</div>
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap font-medium rounded-r-xl">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <button className="btn_item"  onClick={() => handleEditClick(report._id, report.lesson.class_level)}>
                          <FaEye />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}

              <tr className="my-12">
                <th colSpan="4" className="px-6 py-6 text-left font-medium text-white">
                <div className="text-white text-xl">
                  {totalItems === 0 ? (
                    "No entries to show"
                    ) : totalItems === 1 ? (
                    `Showing: 1 of 1 entry`
                    ) : (
                    `Showing: ${1 + (currentPage - 1) * ITEMS_PER_PAGE} to ${Math.min(currentPage * ITEMS_PER_PAGE, totalItems)} of ${totalItems} entries`
                  )}
                </div>
                </th>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      </>
      )
  );
};

export default MyStudentReportsTable;
