import { Routes, Route } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import DashboardAdmin from "./components/for_admins/DashboardAdmin";
import NotificationPage from "./components/for_admins/NotificationPage";
import ClassSearchPage from "./components/for_admins/classes/ClassSearchPage";
import DayPage from "./components/for_admins/classes/DayPage";
import StudentSearchPage from "./components/for_admins/students/StudentSearchPage";
import AddStudent from "./components/for_admins/students/AddStudent";
import StudentReportsPage from "./components/for_admins/students/StudentReportsPage";
import DashboardTeacher from "./components/for_teachers/DashboardTeacher";
import MyStudentsPage from "./components/for_teachers/MyStudentsPage";
import MyStudentReportsPage from "./components/for_teachers/MyStudentReportsPage";
import AddLesson from "./components/for_admins/timetable/AddLesson";
import UpdateReportPage from "./components/for_teachers/UpdateReportPage";
import ParentSearchPage from "./components/for_admins/parents/ParentSearchPage";
import StudentSummariesPage from "./components/for_admins/parents/StudentSummariesPage";
// import UpdateSummaryPage from "./components/for_admins/parents/UpdateSummaryPage";
import ViewSummaryPage from "./components/for_admins/parents/ViewSummaryPage";

// import { getSettings, resetLoadSettings } from "./features/settings/settingSlice";
import { checkToken } from "./features/auth/authSlice";
import { getPendingReportsByTeacher, getAllPendingReports } from "./features/reports/reportSlice";
import { getAllPendingSummaries } from "./features/summaries/summarySlice";

import { run_logout } from "./app/user_utils";
import { is_summary_page } from "./app/utils"
import { PATH_NAME } from "./app/constants";

function App() {

  // const { settings, is_fetching_settings } = useSelector(state => state.settings);

  const { user,
          is_logging_in,
          is_token_verified,
          is_token_failed,
          message_for_verifying_token } = useSelector(state => state.auth);

  const { pending_reports,
          incomplete_reports, } = useSelector(state => state.reports);

  const { pending_summaries } = useSelector(state => state.summaries);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route location
  
  // const [is_viewing_summary, set_is_viewing_summary] = useState(false);

  // useEffect(() => {

  //   // console.log("App.js >> useEffect >> dispatching getSettings()...");

  //   dispatch( getSettings() );

  // }, [dispatch]);

  // useEffect(() => {

  //   if (settings && settings.length > 0)
  //   {
  //     // console.log(settings);

  //     // console.log("App.js >> useEffect >> dispatching resetLoadSettings()...");

  //     dispatch( resetLoadSettings() );
  //   }

  // }, [settings, dispatch]);

  useEffect(() => {

    // OK to view summary without logging in
    const is_on_summary_page = is_summary_page( location.pathname );

    if (user)
    {
      dispatch( checkToken(user.token) );
    }
    else if (!user
            && !is_logging_in
            && location.pathname !== PATH_NAME.Login
            && is_on_summary_page === false)
    {
      // console.log("User is not logged in. Go to login page... from App.js, location.pathname: ", location.pathname);
      navigate( PATH_NAME.Login );
    }

  }, [user, is_logging_in, location.pathname, dispatch, navigate]);

  const handle_logout = useCallback(() => {

    run_logout(dispatch, navigate); // Call the utility function with dispatch and navigate

  }, [dispatch, navigate]);

  // Logout user if JWT expired
  useEffect(() => {

    if (user)
    {
      if (is_token_failed && message_for_verifying_token === "jwt expired")
      {
          // console.log("JWT expired. Logging out...");
          handle_logout();
      }
    }
  }, [user, is_token_failed, message_for_verifying_token, dispatch, navigate, handle_logout]);

  // Read role-specific data if token is verified
  useEffect(() => {

      if (is_token_verified && user)
      {
        if (user.role === "admin")
        {
            // Dispatch once
            dispatch( getAllPendingReports() );
            dispatch( getAllPendingSummaries() );
        }
        else if (user.role === "teacher")
        {
            // Dispatch once
            dispatch( getPendingReportsByTeacher( user.username ) );
        }
      }

    }, [
        navigate, dispatch,
        user, is_token_verified,
  ]);

  // Update Browser Title - Admin
  useEffect(() => {

      if (user && is_summary_page( location.pathname ) === false)
      {
        if (user.role === "admin")
        {
          let sum = 0;

          if (pending_reports && pending_reports.length > 0)
          {
            sum += pending_reports.length;
          }

          if (pending_summaries && pending_summaries.length > 0)
          {
            sum += pending_summaries.length;
          }

          if (sum > 0)
          {
              document.title = "(" + (sum) + ") HOTS System";
          }
          else
          {
              document.title = "HOTS System";
          }
        }
      }

    }, [user,
        pending_reports,
        pending_summaries,
        location.pathname,
  ]);

  // Update Browser Title - Teacher
  useEffect(() => {

      if (user)
      {
        if (user.role === "teacher")
        {
          if (incomplete_reports && incomplete_reports.length > 0)
          {
              document.title = "(" + (incomplete_reports.length) + ") HOTS System";
          }
          else
          {
              document.title = "HOTS System";
          }
        }
      }
    }, [user,
        incomplete_reports,
  ]);

  // if (is_fetching_settings)
  // {
  //   return <Spinner />; // Handle loading state
  // }

  return (
    <>    
    <Routes>
      <Route path={ PATH_NAME.Dashboard } element={ <Dashboard /> } />  
      <Route path={ PATH_NAME.Login } element={ <Login /> } />

      {/* Admin Routes */}
      <Route path={ PATH_NAME.Admin_Dashboard } element={ <DashboardAdmin /> } />
      <Route path={ PATH_NAME.Admin_Notifications } element={ <NotificationPage /> } />
      <Route path={ PATH_NAME.Admin_Add_Lesson } element={ <AddLesson /> } />
      <Route path={ PATH_NAME.Admin_Class_List } element={ <ClassSearchPage /> } />
      <Route path={ PATH_NAME.Admin_Day_Lessons + "/:the_date" } element={ <DayPage /> } />
      <Route path={ PATH_NAME.Admin_Student_List } element={ <StudentSearchPage /> } />
      <Route path={ PATH_NAME.Admin_Add_Student } element={ <AddStudent /> } />
      <Route path={ PATH_NAME.Admin_Student_Reports + "/:student_id/:student_name" } element={ <StudentReportsPage /> } />
      <Route path={ PATH_NAME.Admin_Parent_List } element={ <ParentSearchPage /> } />
      <Route path={ PATH_NAME.Admin_Student_Summaries + "/:student_id/:student_name" } element={ <StudentSummariesPage /> } />
      <Route path={ PATH_NAME.Admin_Update_Report + "/:class_level/:report_id" } element={ <UpdateReportPage /> } />
      {/* Same Component as Parent's summary page, with /edit_mode added to the end of the path */}
      <Route path={ PATH_NAME.Admin_Update_Summary + "/:shareable_id/:edit_mode" } element={ <ViewSummaryPage /> } />

      {/* Teacher Routes */}
      <Route path={ PATH_NAME.Teacher_Dashboard } element={ <DashboardTeacher /> } />
      <Route path={ PATH_NAME.Teacher_My_Students } element={ <MyStudentsPage /> } />
      <Route path={ PATH_NAME.Teacher_My_Student_Reports + "/:student_id/:student_name" } element={ <MyStudentReportsPage /> } />
      {/* Same Component as Teacher's report page, with /edit_mode added to the end of the path */}
      <Route path={ PATH_NAME.Teacher_Update_Report + "/:class_level/:report_id/:edit_mode" } element={ <UpdateReportPage /> } />
      
      {/* Shared Routes */}
      <Route path={ PATH_NAME.Parent_View_Summary + "/:shareable_id" } element={ <ViewSummaryPage /> } />
      
    </Routes>

    <ToastContainer />
    
    </>
  );
}

export default App;