import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../Spinner";
import { getSummaryBySharedID, signSummary, resetFetchSummary, resetSignSummary } from "../../../features/summaries/summarySlice";
import { downloadVoiceMessage } from "../../../features/files/fileSlice";
import { FaRegSave, FaLink, FaInfoCircle, FaWindowClose, FaPen, FaFileSignature } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa6";
import { FiTrash2, FiDownload } from "react-icons/fi";
import { ImNewTab } from "react-icons/im";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
// import Chart from "chart.js/auto";
import ChartViewer from "./charts/ChartViewer";
// import { CHART_DATA_RED } from "./charts/ChartDataRed";
import { getDateInYyyyMmDd } from "../../../app/utils";
import { PATH_NAME } from "../../../app/constants";

const ViewSummaryPage = () => {
    const { shareable_id, edit_mode } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // console.log("ViewSummaryPage.js >> shareable_id: ", shareable_id);
    // console.log("ViewSummaryPage.js >> edit_mode: ", edit_mode);

    const { fetched_summary, is_fetching_summary, is_fetched_summary, is_error_fetching_summary,
            is_signing_summary, is_signed_summary, is_error_signing_summary
                                } = useSelector((state) => state.summaries);

    const { download_response, is_downloaded_voice_message,
        // is_downloading_voice_message, 
                                } = useSelector((state) => state.media_downloads);

    const this_shareable_id = shareable_id ? shareable_id : null;
    const is_in_edit_mode = (edit_mode === "edit_mode");

    const [ canRenderPage, setCanRenderPage ] = useState(false);
    const [ notFound, setNotFound ] = useState(false);

    const [ isEditingPassage, setIsEditingPassage ] = useState(false);
    const [ summaryPassage, setSummaryPassage ] = useState("");

    const [ signature, setSignature ] = useState("");

    // Set state for the included voice message URLs
    // const [voiceMessageUrls, setVoiceMessageUrls] = useState([]);
    const [ latest_audio_urls, set_latest_audio_urls ] = useState([]);
    const [ removing_audio_url, set_removing_audio_url ] = useState(null); // Track which URL is being removed
    const [ latest_photo_urls, set_latest_photo_urls ] = useState([]);
    const [ removing_photo_url, set_removing_photo_url ] = useState(null); // Track which URL is being removed
    const [ latest_video_urls, set_latest_video_urls ] = useState([]);
    const [ removing_video_url, set_removing_video_url ] = useState(null); // Track which URL is being removed

    const [ selectedMedia, setSelectedMedia ] = useState(null); // State for selected media (photo)

    const [ input_content, set_input_content ] = useState([]);

    useEffect(() => {

        if (this_shareable_id === null)
        {
            setNotFound(true);
        }
        else
        {
            // Check this scenario again later: shareable_id changed in the address bar
            // Reset might not have been called if user typed in another shareable_id in the address bar
            // Clean up everything before fetching new summary, just in case
            dispatch( resetFetchSummary() );

            // console.log("ViewSummaryPage.js >> useEffect :: dispatch fetch summary by shareable_id: ", this_shareable_id);
            dispatch( getSummaryBySharedID( this_shareable_id ) );
        }
    
        return () => {

            // console.log("ViewSummaryPage.js >> useEffect :: resetFetchSummary");
            dispatch( resetFetchSummary() );
        };

    }, [this_shareable_id, dispatch]);

    useEffect(() => {

        if (is_error_fetching_summary)
        {
            // console.log("ViewSummaryPage.js >> useEffect :: error fetching summary");

            setNotFound(true);
            setCanRenderPage(true);
        }

    }, [is_error_fetching_summary]);

    useEffect(() => {

        if (is_fetched_summary && fetched_summary)
        {
            set_latest_audio_urls( fetched_summary.included_voice_message_urls );
            set_latest_photo_urls( fetched_summary.included_photo_urls );
            set_latest_video_urls( fetched_summary.included_video_urls );

            // Only set the signature if it exists
            if ( fetched_summary.admin_signature) setSignature( fetched_summary.admin_signature );
            
            // For console logging without dependencies
            // const temp_input_content = [];

            // Reset chart content first
            set_input_content([]);

            // Populate chart content
            for (let i = 0; i < fetched_summary.reports.length; i++)
            {
                const content = fetched_summary.reports[i].content;

                set_input_content((prevContent) => [...prevContent,
                                                    {"content": content}]);

                // temp_input_content.push({"content": content});

                // console.log("ViewSummaryPage.js >> useEffect :: chart content: ", temp_input_content);
            }

            setNotFound(false);
            setCanRenderPage(true);
        }

    }, [fetched_summary, is_fetched_summary]);

    useEffect(() => {

        if (is_in_edit_mode === false && is_fetched_summary && fetched_summary)
        {
            // console.log("ViewSummaryPage.js >> useEffect :: update browser title");

            const new_title = "Progress Summary - " + fetched_summary.student.student_name;

            // Only update document title if it has actually changed
            if (document.title !== new_title)
            {
                document.title = new_title;
            }
        }

    }, [is_in_edit_mode, fetched_summary, is_fetched_summary]);

    //------------------------------------------------
    // Signature
    //------------------------------------------------

    useEffect(() => {

        if (is_signed_summary)
        {
            // console.log("ViewSummaryPage.js >> useEffect :: successfully signed summary");

            // Go back to the student's page
            navigate(PATH_NAME.Admin_Student_Summaries + "/" + fetched_summary.student.student_id + "/" + fetched_summary.student.student_name);

            dispatch( resetSignSummary() );
        }

    }, [is_signed_summary, fetched_summary, dispatch, navigate]);

    useEffect(() => {

        if (is_error_signing_summary)
        {
            // console.log("ViewSummaryPage.js >> useEffect :: error signing summary");

            // Go back to the student's page (without signing )
            navigate(PATH_NAME.Admin_Student_Summaries + "/" + fetched_summary.student.student_id + "/" + fetched_summary.student.student_name);

            dispatch( resetSignSummary() );
        }
    }, [is_error_signing_summary, fetched_summary, dispatch, navigate]);


    //------------------------------------------------
    // Download Voice Message
    //------------------------------------------------

    useEffect(() => {

        if (download_response && is_downloaded_voice_message)
        {
            console.log("ViewSummaryPage.js >> useEffect :: download_response: ", download_response);
            // const new_blob = await download_response.blob();

            // // Create a temporary URL and download link
            // const url = window.URL.createObjectURL(new_blob);
            // const link = document.createElement("a");
            // link.href = url;
            // link.setAttribute("download", "output.m4a");
            // document.body.appendChild(link);
            // link.click();

            // // Clean up
            // link.parentNode.removeChild(link);
            // window.URL.revokeObjectURL(url);
        }
    }, [download_response, is_downloaded_voice_message]);

    // Generate PDF
    function exportPDF()
    {
        const input_page = document.querySelector("#summary_page");//document.getElementById("summary_page");
        
        html2canvas(input_page, { scale: 2, // Increase scale for better resolution            
                            useCORS: true,
                            // Ignore elements with class "exclude_from_pdf"
                            ignoreElements: (element) => {
                                return element.classList.contains("exclude_from_pdf");
                            } }) 
            .then((canvas) => {
                const imgWidth = 210; // A4 width in mm
                const pageHeight = 295; // A4 height in mm
                const imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;

                const imgData = canvas.toDataURL('image/jpeg', 1.0); // Using JPEG for better text rendering
                const pdf = new jsPDF('p', 'mm', 'a4'); // Set to A4 size
                
                let position = 0;

                pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save("student_summary_" + shareable_id + ".pdf");
            });
    }

    const handleVoiceMessageDownload = (url) => {

        dispatch( downloadVoiceMessage(url) );
    };

    // Function to remove a specific audio row
    const removeAudioRow = (urlToRemove) => {

        if (removing_audio_url) return; // Prevent multiple removals at the same time

        // Set the removing state for the specific URL
        set_removing_audio_url(urlToRemove);
    
        // Simulate an async operation, like an API call
        setTimeout(() => {

            // Remove the URL from the state after the "removal" completes
            set_latest_audio_urls((prevUrls) => prevUrls.filter((url) => url !== urlToRemove));

            // Update summary data as well
            // todo

            // Clear the removing state
            set_removing_audio_url(null);

        }, 1500); // Simulate a 1.5 second delay for removal
    };

    // Function to remove a specific photo
    const removePhoto = (urlToRemove) => {

        if (removing_photo_url) return; // Prevent multiple removals at the same time

        // Set the removing state for the specific URL
        set_removing_photo_url(urlToRemove);
    
        // Simulate an async operation, like an API call
        setTimeout(() => {

            // Remove the URL from the state after the "removal" completes
            set_latest_photo_urls((prevUrls) => prevUrls.filter((url) => url !== urlToRemove));

            // Update summary data as well
            // todo

            // Clear the removing state
            set_removing_photo_url(null);

        }, 1500); // Simulate a 1.5 second delay for removal
    };

    // Function to remove a specific video
    const removeVideo = (urlToRemove) => {

        if (removing_video_url) return; // Prevent multiple removals at the same time

        // Set the removing state for the specific URL
        set_removing_video_url(urlToRemove);
    
        // Simulate an async operation, like an API call
        setTimeout(() => {

            // Remove the URL from the state after the "removal" completes
            set_latest_video_urls((prevUrls) => prevUrls.filter((url) => url !== urlToRemove));

            // Update summary data as well
            // todo

            // Clear the removing state
            set_removing_video_url(null);

        }, 1500); // Simulate a 1.5 second delay for removal
    };

    const editPassage = () => {
            
        setIsEditingPassage(true);
        setSummaryPassage(fetched_summary.output_summary);
    };

    const handleSaveSignature = () => {

        // console.log("ViewSummaryPage.js >> handleSaveSignature :: signature: ", signature);

        dispatch( signSummary( {
                                shareable_id: this_shareable_id,
                                admin_signature: signature,
                                status: fetched_summary.status} )
        );
    };

    const handleSaveSummary = () => {

        const updated_summary = {
            shareable_id: this_shareable_id,
            output_summary: summaryPassage,
            included_voice_message_urls: latest_audio_urls,
            included_photo_urls: latest_photo_urls,
            included_video_urls: latest_video_urls,
            // status: fetched_summary.status
        };

        console.log("ViewSummaryPage.js >> handleSaveSummary :: updated_summary: ", updated_summary);
    };

    return (
        
        (!canRenderPage || is_fetching_summary || is_signing_summary) ? <Spinner /> :
        
        (
            canRenderPage &&
            <>
            {
                notFound &&

                <div id="404_page" className="bg-gray-200 w-full mx-auto px-5 min-h-screen flex flex-col justify-between text-center">
                    <p>This page cannot be found, or it may have been removed.</p>
                </div>
            }
            {
                !notFound && fetched_summary && is_in_edit_mode &&

                <div id="edit_summary_header_bar" className="bg-rose-200 w-full mx-auto px-5 flex flex-col justify-between">
                    <div id="edit_summary_header"
                        className="max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-md w-full mx-auto px-5 xl:px-20 text-left mt-20">


                        <Link to={PATH_NAME.Admin_Dashboard} >
                            &lt;&lt;&nbsp; Back to Admin Corner
                        </Link>
                        <div className="text-5xl text-blue-950 font-bold my-12 flex items-center">
                            <FaInfoCircle className="mr-6" /> [Admin Version]
                        </div>

                        <div className="flex items-center space-x-4 mt-20 mb-20">
                            <Link to={PATH_NAME.Parent_View_Summary + "/" + this_shareable_id} target="_blank" rel="noopener noreferrer" >
                            <button
                                className="bg-white text-gray-700 text-3xl font-semibold py-8 px-10
                                        rounded-xl shadow hover:bg-gray-100 flex items-center space-x-2
                                        transform hover:scale-105 transition-transform duration-300 ease-in-out"
                            >
                                Public Link <span className="ml-8"><FaLink /></span>
                            </button>
                            </Link>
                            
                            <Link to={PATH_NAME.Parent_View_Summary + "/" + this_shareable_id} target="_blank" rel="noopener noreferrer" >
                            <div className="flex items-center space-x-2 text-gray-700 text-xl">
                                <span>Opens in New Tab</span> <ImNewTab />
                            </div>
                            </Link>
                        </div>

                        {/* <button className="bg-white text-gray-700 text-3xl font-semibold py-8 px-10 mt-20 mb-20
                                            rounded-xl shadow
                                            hover:bg-gray-100 flex items-center space-x-2 transform
                                            hover:scale-105 transition-transform duration-300 ease-in-out"
                                onClick={exportPDF}>
                            Save Edits<span className="ml-8"><FaRegSave /></span>
                        </button> */}

                        <div className="text-3xl text-rose-800 font-medium mt-12 mb-6">
                            <p>&darr;&nbsp; Summary Begins &nbsp;&darr;</p>
                        </div>
                    </div>
                    
                </div>
            }
            {
                !notFound && fetched_summary &&

                <div id="summary_page" className="bg-stone-200 w-full mx-auto px-5 min-h-screen flex flex-col justify-between">
                    
                    <div id="summary_header"
                        className="max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-md w-full mx-auto px-5 xl:px-20 text-left mt-40">
                        
                        <div className="text-4xl text-gray-700 font-medium my-12">
                            { getDateInYyyyMmDd( new Date(fetched_summary.createdAt)) }
                        </div>
                        <div className="text-6xl text-gray-700 font-bold my-12">
                            Progress Summary for {fetched_summary.student.student_name}
                        </div>
                        <div className="text-6xl text-gray-700 font-bold my-12">
                            進度報告
                        </div>

                        <button className="exclude_from_pdf
                                            bg-white text-gray-700 text-3xl font-semibold py-8 px-10 mt-20 mb-20
                                            rounded-xl shadow
                                            hover:bg-gray-100 flex items-center space-x-2 transform
                                            hover:scale-105 transition-transform duration-300 ease-in-out"
                                onClick={exportPDF}>
                            下載 PDF<span className="ml-8"><FaRegFilePdf /></span>
                        </button>
                        
                    </div>

                    <div id="summary_comment"
                        className="max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-md w-full mx-auto px-5 xl:px-20 text-left mt-20">
                        
                        { is_in_edit_mode ? (
                            <>
                            <div className="flex items-center">
                             <div className="text-5xl text-cyan-600 font-bold my-12">總結：</div>
                             <button
                                onClick={ editPassage }
                                className="ml-4 bg-hots-red text-white text-3xl font-semibold py-6 px-6 rounded-xl shadow hover:bg-red-600 flex justify-center items-center space-x-2 transform hover:scale-105 transition-transform duration-300 ease-in-out"
                            >
                                <FaPen />
                            </button>
                            </div>
                            </>
                        ) : (
                            <>
                            <div className="text-5xl text-cyan-600 font-bold my-12">總結：</div>
                            </>
                        )
                        }

                        { isEditingPassage &&
                            <>
                            <textarea
                                value={summaryPassage}
                                onChange={(e) => setSummaryPassage(e.target.value)}
                                className="w-full h-64 p-4 text-3xl rounded-xl shadow-md" 
                                rows="10" 
                            />

                            <div className="text-3xl text-gray-600 font-bold mt-8">
                                Original:
                            </div>
                            </>
                            
                        }

                        <div className="text-3xl text-gray-600 font-regular leading-relaxed my-12">
                            {fetched_summary.output_summary}
                        </div>

                        <hr className="mt-20 mb-8 border-gray-600 border-t-2"></hr>

                        <div className="mt-16 mb-20">

                            <p className="text-3xl text-gray-600 font-bold mt-12 mb-12">
                                老師評語
                            </p>

                            {input_content.map((one_set, index) => (
                                <React.Fragment key={index}> {/* Add the key to the fragment */}
                                <p 
                                  className="text-2xl text-hots-green-1 font-bold mt-12 mb-4"
                                >
                                  Week {(index + 1)}: <br/>
                                </p>
                                <p
                                  className="text-2xl text-gray-600 font-regular mt-4 mb-12"
                                >
                                  {one_set.content.weekly_comment}
                                </p>
                                </React.Fragment>
                            ))}
                        </div>

                        <hr className="mt-10 mb-40 border-gray-700 border-t-2"></hr>
 
                    </div>

                    {
                        is_in_edit_mode &&
                        <>
                        <div className="exclude_from_pdf
                                        bg-rose-200 w-full flex flex-col justify-between px-0 mt-40">
                            <div
                                className="max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-md w-full mx-auto px-5 xl:px-20 text-left mt-20">
                                
                                <div className="text-5xl text-blue-950 font-bold my-12 flex items-center">
                                    <FaInfoCircle className="mr-4" /> Admin
                                </div>

                                <div className="text-3xl text-blue-900 font-medium my-12 flex items-center">
                                    Remove any voice messages that are not a good fit for the summary.
                                </div>

                                <div className="text-2xl text-blue-900 font-medium my-12 flex items-center">
                                    (They will still be stored in the original report.)
                                </div>
                            </div>
                        </div>
                        </>
                    }

                    <div id="summary_audio"
                        className="exclude_from_pdf
                                    w-full max-w-screen-xl mx-auto p-8 md:p-16
                                    rounded-4xl shadow-lg bg-gradient-to-br from-gray-700 to-gray-500 mt-40 mb-20">
                        <h2 className="text-white text-7xl font-bold my-16">老師的話</h2>
                        <p className="text-gray-300 text-xl my-12">老師給小朋友的錄音</p>

                        {latest_audio_urls.length === 0 && (
                            <p className="text-gray-300 text-xl my-12">(暫時未有錄音)</p>
                        )}
                        {
                            // fetched_summary.included_voice_message_urls.map((url, index) => (
                            latest_audio_urls.map((url, index) => (
                                <div key={index} className="flex items-center my-6">
                                {/* <p>{url}</p> */}
                                <audio controls className="my-2" src={url} type="audio/mpeg">
                                    Your browser does not support the audio element.
                                </audio>

                                {/* Show the remove button only in edit mode */}
                                {is_in_edit_mode &&
                                <>
                                
                                    {/* Show loading message if the current URL is being removed */}
                                    {removing_audio_url === url ? (
                                        <p className="ml-4 text-gray-100">Removing file from list, please wait...</p>
                                    ) : (
                                        <>
                                        <button
                                            onClick={() => removeAudioRow(url)}
                                            className="ml-4 bg-hots-red text-white text-2xl font-semibold py-2 px-6 rounded-xl shadow
                                                hover:bg-red-600 flex justify-center items-center space-x-2 transform
                                                hover:scale-105 transition-transform duration-300 ease-in-out"
                                        >
                                            <FiTrash2 />
                                        </button>
                                        </>
                                    )}
                                
                                </>}

                                <button
                                    onClick={() => handleVoiceMessageDownload(url)}
                                    className="ml-4 bg-white text-gray-700 text-2xl font-semibold py-2 px-6 rounded-xl shadow
                                                        hover:bg-gray-100 flex justify-center items-center space-x-2 transform
                                                        hover:scale-105 transition-transform duration-300 ease-in-out"
                                >
                                    <FiDownload />
                                </button>

                                </div>
                            ))
                        }
                        {/* <button className="bg-white text-gray-700 text-2xl font-semibold py-6 px-10 mb-20
                                            rounded-xl shadow
                                            hover:bg-gray-100 flex items-center space-x-2 transform
                                            hover:scale-105 transition-transform duration-300 ease-in-out">
                            下載 <span className="ml-8"><FiDownload /></span>
                        </button> */}

                    </div>

                    <div id="summary_charts"
                        className="w-full max-w-screen-xl mx-auto p-8 md:p-16
                                    rounded-4xl shadow-lg bg-gradient-to-br from-gray-700 to-gray-500 mt-40 mb-20">
                        <h2 className="text-white text-7xl font-bold my-16">進度評分圖表</h2>
                        <p className="text-gray-300 text-xl my-12">細看小朋友的進步走勢</p>

                        <ChartViewer chart_data={input_content} class_level={fetched_summary.class_level} />

                    </div>

                    {
                        is_in_edit_mode &&
                        <>
                        <div className="exclude_from_pdf
                                        bg-rose-200 w-full flex flex-col justify-between px-0">
                            <div 
                                className="max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-md w-full mx-auto px-5 xl:px-20 text-left mt-20">
                                
                                <div className="text-5xl text-blue-950 font-bold my-12 flex items-center">
                                    <FaInfoCircle className="mr-4" /> Admin
                                </div>

                                <div className="text-3xl text-blue-900 font-medium my-12 flex items-center">
                                    Remove any photos / videos that are not a good fit for the summary.
                                </div>

                                <div className="text-2xl text-blue-900 font-medium my-12 flex items-center">
                                    (They will still be stored in the original report.)
                                </div>
                            </div>
                        </div>
                        </>
                    }

                    <div id="summary_media"
                        className="w-full max-w-screen-xl mx-auto p-8 md:p-16
                                    rounded-4xl shadow-lg bg-gradient-to-br from-gray-700 to-gray-500 mt-40 mb-20">
                        <h2 className="text-white text-7xl font-bold my-16">上課互動花絮</h2>
                        <p className="text-gray-300 text-xl my-12">老師特選的相片及影片集</p>

                        <div id="photo_gallery" className="grid grid-cols-1 md:grid-cols-2 gap-8">

                            {/* Photos Section */}
                            {latest_photo_urls.length === 0 && (
                                <p className="text-gray-300 text-xl my-12">(暫時未有相片)</p>
                            )}
                            {latest_photo_urls.length > 0 && (
                            <>
                                <h3 className="text-gray-100 text-2xl font-semibold col-span-full">相片</h3>

                                {latest_photo_urls.map((url, index) => (
                                <div key={url} className="relative flex flex-col items-center">
                                    {removing_photo_url === url ? (
                                        <p className="text-gray-100 text-xl">Removing photo, please wait...</p>
                                    ) : (
                                    is_in_edit_mode && (
                                        <button
                                            className="exclude_from_pdf
                                                        absolute top-0 right-0
                                                        mt-2 mr-2 bg-hots-red text-white p-2 rounded-full shadow
                                                        hover:bg-red-600 transition-transform transform hover:scale-105"
                                            onClick={() => removePhoto(url)}
                                        >
                                            <FiTrash2 className="text-3xl" />
                                        </button>
                                    )
                                    )}
                                    <img
                                        src={url}
                                        alt={`File ${index + 1}`}
                                        className="rounded-xl shadow-md cursor-pointer max-h-80"
                                        onClick={() => setSelectedMedia({ type: "photo", url: url })}
                                    />
                                </div>
                                ))}
                            </>
                            )}

                            {/* Videos Section */}
                            {/* {fetched_summary.included_video_urls && fetched_summary.included_video_urls.length > 0 && (
                            <>
                                <h3 className="exclude_from_pdf
                                                text-gray-100 text-2xl font-semibold col-span-full">影片</h3>
                                {fetched_summary.included_video_urls.map((videoUrl, index) => (
                                <div key={index} className="exclude_from_pdf
                                                            flex flex-col items-center">
                                    <video
                                    src={videoUrl}
                                    controls
                                    className="rounded-xl shadow-md cursor-pointer max-h-80"
                                    // onClick={() => setSelectedMedia({ type: 'video', url: videoUrl })}
                                    >
                                    Your browser does not support the video element.
                                    </video>
                                    
                                </div>
                                ))}
                            </>
                            )} */}

                            {latest_video_urls.length === 0 && (
                                <p className="text-gray-300 text-xl my-12">(暫時未有影片)</p>
                            )}

                            {latest_video_urls.length > 0 && (
                                <>
                                    <h3 className="exclude_from_pdf
                                                    text-gray-100 text-2xl font-semibold col-span-full">影片</h3>
                                    {latest_video_urls.map((url, index) => (
                                    <div key={url} className="exclude_from_pdf
                                                                relative flex flex-col items-center">
                                        {removing_video_url === url ? (
                                        <p className="text-gray-100 text-xl">Removing video, please wait...</p>
                                        ) : (
                                        is_in_edit_mode && (
                                            <button
                                            onClick={() => removeVideo(url)}
                                            className="absolute top-0 right-0
                                                        mt-2 mr-2 bg-hots-red text-white p-2 rounded-full shadow
                                                        hover:bg-red-600 transition-transform transform hover:scale-105 z-10"
                                            >
                                                <FiTrash2 className="text-3xl" />
                                            </button>
                                        )
                                        )}
                                        <video
                                            src={url}
                                            controls
                                            className="rounded-xl shadow-md cursor-pointer max-h-80"
                                        >
                                            Your browser does not support the video element.
                                        </video>
                                        
                                    </div>
                                    ))}
                                </>
                            )}
                        </div>

                        {/* Selected Media Modal */}
                        {selectedMedia && (
                            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                                    onClick={() => setSelectedMedia(null)} // Also close the modal when clicking on the overlay
                            >
                            <div className="relative bg-white rounded-lg p-8 shadow-lg max-w-full max-h-full overflow-auto">
                                <button
                                onClick={() => setSelectedMedia(null)}
                                // className="image_close_button"
                                className="absolute top-10 right-10 m-2 text-white hover:text-gray-100"
                                >
                                    <FaWindowClose className="text-5xl" /> {/* Ensure the icon is properly displayed */}
                                </button>
                                {selectedMedia.type === 'photo' && (
                                    <img src={selectedMedia.url}
                                        alt="Selected Media"
                                        className="max-w-full max-h-screen object-contain" />
                                )}
                            </div>
                            </div>
                        )}

                    </div>

                    <div id="summary_footer"
                        className="max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-md w-full mx-auto px-5 xl:px-20 text-left
                                mt-40 mb-60">
                            
                        <div className="text-6xl text-gray-700 font-semibold my-12">About the Class Level</div>

                        <div className="text-3xl text-gray-600 font-regular my-12">
                            {fetched_summary.student.preferred_name} is currently taking our {fetched_summary.class_level} class.
                        </div>

                        <div className="text-6xl text-gray-700 font-semibold my-12">關於課堂</div>

                        <div className="text-3xl text-gray-600 font-regular my-12">
                            {fetched_summary.student.preferred_name} 目前正在 {fetched_summary.class_level} 課堂中學習。
                        </div>

                        <hr className="mt-40 mb-8 border-gray-700 border-t-2"></hr>

                        <div className="text-xl text-gray-800 font-regular mt-8 mb-4">HOTS Learning Centre</div>

                        <div className="text-xl text-gray-800 font-regular mt-2 mb-8">高階思維學習中心</div>
 
                    </div>

                </div>

                
            }

            {
                !notFound && fetched_summary && is_in_edit_mode &&

                <div id="edit_summary_footer_bar" className="bg-rose-200 w-full mx-auto px-5 flex flex-col justify-between">

                    <div id="edit_summary_footer"
                        className="max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-md w-full mx-auto px-5 xl:px-20 text-left">
                        
                        <div className="text-3xl text-rose-800 font-medium mt-4 mb-12">
                            <p>&uarr;&nbsp; End of Summary &nbsp;&uarr;</p>
                        </div>

                        <div className="text-5xl text-blue-950 font-bold mt-24 mb-12 flex items-center">
                            <FaFileSignature className="mr-6" /> Admin Signature
                        </div>

                        <input 
                            type="text" 
                            className="border border-gray-300 rounded px-4 py-4 text-4xl mt-16 mb-8"
                            placeholder="Type your name here"
                            value={ signature }
                            onChange={(e) => setSignature(e.target.value)}
                            disabled={ fetched_summary.admin_signature ? true : false }
                        />

                        <div className="text-3xl text-blue-950 font-medium mt-6 mb-12 flex items-center">
                            By typing your name in the box, you confirm that the summary has been properly edited,
                            and that its Public Link has been shared with the student's parent.
                        </div>

                        { fetched_summary.admin_signature === null &&
                        <button className="bg-hots-green-1 text-white text-3xl font-semibold py-8 px-10 mt-16 mb-8
                                            rounded-xl shadow
                                            hover:bg-cyan-900 flex items-center space-x-2 transform
                                            hover:scale-105 transition-transform duration-300 ease-in-out"
                                onClick={ handleSaveSignature }>
                            Save Signature<span className="ml-8"><FaRegSave /></span>
                        </button>
                        }

                        <hr className="mt-24 mb-16 border-blue-900 border-t-4"></hr>

                        <div className="text-3xl text-blue-900 font-medium mt-20 mb-12 flex items-center">
                            If this summary has not been shared with the parent yet, you can
                            still save your edits by clicking the button below.
                        </div>

                        <button className="bg-hots-purple text-white text-3xl font-semibold py-8 px-10 mt-16 mb-8
                                            rounded-xl shadow
                                            hover:bg-indigo-900 flex items-center space-x-2 transform
                                            hover:scale-105 transition-transform duration-300 ease-in-out"
                                onClick={ handleSaveSummary }>
                            Save All Edits<span className="ml-8"><FaRegSave /></span>
                        </button>

                        <div className="mt-8 mb-40">
                            <Link to={PATH_NAME.Admin_Dashboard}>
                                &lt;&lt;&nbsp; Back to Admin Corner (without saving)
                            </Link>
                        </div>
                        
                    </div>
                    
                </div>
            }
            </>
        )
    );
};

export default ViewSummaryPage;