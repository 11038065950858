import axios from "axios";

import { MAIN_URL } from "../../app/config";

const API_URL = MAIN_URL + "/api/summaries/"

const generateSummary = async (input_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("summaryService.js >> generateSummary :: student_id: ", input_data.student_id);
      
    const response = await axios.post(API_URL + "generate/" + input_data.student_id, input_data, config);
    return response.data;
}

const getSummaryBySharedID = async (shareable_id) => {
    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //     }
    // };
    const response = await axios.get(API_URL + "/" + shareable_id);
    return response.data;
}

const signSummary = async (summary_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.put(API_URL + "sign/" + summary_data.shareable_id, summary_data, config);
    return response.data;
}

const getAllPendingSummaries = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("summaryService.js >> getAllPendingSummaries ", config);

    const response = await axios.get(API_URL + "pending/all", config);
    return response.data;
}

const getSummariesByStudent = async (student_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL + "student/" + student_id, config);
    return response.data;
}

const getTranslatedSummary = async (input_comments) => {
    try
    {
        // console.log("summaryService.js >> getTranslatedSummary :: input_comments: ", input_comments);

        const response = await axios.post(API_URL + "summarize", input_comments);

        // if (!response.ok)
        // {
        //     throw new Error("Failed to get translated summary.");
        // }

        // console.log("summaryService.js >> getTranslatedSummary :: response: ", response);

        // const data = await response.json();

        if (response.status === 200 && response.data.summary)
        {
            return response.data.summary;
        }

        // Return null and let client handle the error
        return null;
    }
    catch (error)
    {
        console.error("Error getting translated summary:", error);
        return null;
    }
}

const summaryService = { generateSummary, getSummaryBySharedID,
                        signSummary,
                        getAllPendingSummaries,
                        getSummariesByStudent, getTranslatedSummary };

export default summaryService;