import { FaChalkboard } from "react-icons/fa";

const TabButtonClasses = () => {

    return (
        window.location.href.includes("/class_list") === true ? (
            <button className="btn_white_outline_highlighted">
                <FaChalkboard />&nbsp;Classes
            </button>
        ) : (
            <button className="btn_white_outline">
                <FaChalkboard />&nbsp;Classes
            </button>
        )
    );
};

export default TabButtonClasses;