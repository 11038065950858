import { Link } from "react-router-dom";
import { PATH_NAME } from "../../app/constants";

const FooterTeacher = () => {

    return (
        <footer className="bg-gray-200 py-12 mt-8 shadow-md top-full sticky">
            <Link to={ PATH_NAME.Teacher_Dashboard }>
                <p className="text-gray-500">HOTS System - Teacher's Corner</p>
            </Link>
        </footer>
    );

}

export default FooterTeacher;