export const ReportContentData_Yellow = {
    _1_: "3",
    _2_1_: "3",
    _2_2_: "3",
    _2_3_: "3",
    _2_4_: "3",
    _2_5_: "3",
    _2_6_: "3",
    _3_1_: "3",
    _3_2_: "3",
    _4_: "3",
    _5_: "3",
    _6_1_: "3",
    _6_2_: "3",
    _6_3_: "3",
    _6_4_: "3",
    _6_5_: "3",
    _7_1_: "3",
    _7_2_: "3",
    _7_3_: "3",
    _8_1_: "3",
    _8_2_: "3",
    _8_3_: "3",
    _8_4_: "3",
    _8_5_: "3",
    _8_6_: "3",
    _8_7_: "3",
    _8_8_: "3",
    _8_9_: "3",
    _8_10_: "3",
    weekly_comment: "",
}

export const ReportContentData_Red =
{
    key_object: "",
    key_letter: "",
    _1_: "3",
    _2_: "3",
    _3_: "3",
    _4_: "3",
    _5_: "3",
    _6_: "3",
    _7_: "3",
    _8_: "3",
    _9_: "3",
    _10_: "3",
    _11_: "3",
    // _1_phonics: "3",
    // _2_communication: "3",
    // _3_listening: "3",
    // _4_speaking: "3",
    // _5_reading: "3",
    // _6_letter_recognition: "3",
    // _7_tracing_paper: "3",
    // _8_story_making: "3",
    // _9_eagerness: "3",
    // _10_coachiness: "3",
    // _11_discipline: "3",
    can_advance: false,
    weekly_comment: "",
};