import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PATH_NAME } from "../../../app/constants";
import { getOneStudent, updateOneStudent,
          resetCurrentStudent, resetFetchStudent, resetUpdateStudent } from "../../../features/students/studentSlice";
import { FaPen, FaRegSave } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const StudentInfoBox = ({ student_id, student_name }) => {

  const curr_student_id = student_id ? student_id : null;
  const curr_student_name = student_name ? student_name : null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [canRenderBox, setCanRenderBox] = useState(false);

  const { current_student,
          is_fetched_student, is_fetching_student,
          is_updated_student, is_updating_student,
        } = useSelector((state) => state.students);

  const [isEditingName, setIsEditingName] = useState(false);
  const [preferredName, setPreferredName] = useState("");
  const [originalName, setOriginalName] = useState("");

  useEffect(() => {

    if (is_fetched_student && current_student !== null)
    {
      setPreferredName(current_student.preferred_name);
      setOriginalName(current_student.preferred_name);
      setCanRenderBox(true);

      dispatch( resetFetchStudent() );
    }

  }, [current_student, is_fetched_student, dispatch]);

  useEffect(() => {

    if (is_updated_student && current_student !== null)
    {
      setCanRenderBox(true);

      dispatch( resetUpdateStudent() );
    }

  }, [current_student, is_updated_student, dispatch]);

  useEffect(() => {

    if (curr_student_id === null || curr_student_name === null)
    {
        navigate( PATH_NAME.Dashboard );

        return;
    }

    dispatch( getOneStudent( curr_student_id ) );

    return () => {
        dispatch( resetCurrentStudent() );
        dispatch( resetFetchStudent() );
        dispatch( resetUpdateStudent() );
    };
  }, [curr_student_id, curr_student_name, navigate, dispatch]);

  const handleEditClick = () => {

    setOriginalName(preferredName); // Store the original name before editing
    
    setIsEditingName(true);
  };

  const handleSaveClick = () => {

    setIsEditingName(false);

    dispatch( updateOneStudent({ student_id: curr_student_id, preferred_name: preferredName }) );

    setCanRenderBox(false);
  };

  const cancelEdit = () => {

    setPreferredName(originalName); // Revert to the original name
    
    setIsEditingName(false);
  };

  const handleChange = (e) => {
    setPreferredName(e.target.value);
  };

  return (
    <>

    {/* Top Line */}
    <hr className="my-6 border-white border-t-8 regular"></hr>

    {
      canRenderBox && current_student !== null &&
      (
        <>

        <p className="my-6 text-white font-bold text-2xl">
            ({curr_student_id})
        </p>
        
        <p className="my-6 text-gray-600 font-extrabold text-4xl">
            {curr_student_name}
        </p>

        {/* Preferred Name Field */}
        <div className="flex justify-center items-center my-6">
          <div className="flex items-center">
            {isEditingName ? (
              <>
              <p className="text-gray-500 font-bold text-3xl mr-6">
                Preferred Name: 
              </p>
              <input 
                type="text" 
                value={preferredName} 
                onChange={handleChange} 
                className="border border-gray-300 rounded px-2 py-1 text-3xl"
              />
              </>
            ) : (
              <p className="text-gray-500 font-semibold text-3xl">
                Preferred Name: {preferredName}
              </p>
            )}
            <button onClick={isEditingName ? handleSaveClick : handleEditClick} className="btn_item ml-4">
              {isEditingName ? <FaRegSave /> : <FaPen />}
            </button>

            {isEditingName && (
              <button onClick={cancelEdit} className="btn_item_gray ml-4">
                <ImCross />
              </button>
            )}
          </div>
        </div>

        </>
      )
    }

    {
      is_fetching_student &&
      (
        <>
        <p className="my-6 text-gray-100 font-bold text-2xl">
            Loading student information...
        </p>
        </>
      )
    }

    {
      is_updating_student &&
      (
        <>
        <p className="my-6 text-gray-100 font-bold text-2xl">
            Saving student information, please wait...
        </p>
        </>
      )
    }

    {/* Bottom Line */}
    <hr className="my-10 border-white border-t-8 regular"></hr>

    </>
  );
};

export default StudentInfoBox;
