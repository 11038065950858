//import { FaSignInAlt } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../features/auth/authSlice";
import Spinner from "./Spinner";
import { PATH_NAME } from "../app/constants";

const Login = () => {
    const [form_data, set_form_data] = useState({
        username: "",
        password: ""
    });

    const { username, password } = form_data;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user,
            is_logging_in,
            is_logged_in,
            is_error_logging_in,
            message_for_login } = useSelector(state => state.auth);

    useEffect(() => {
        if (is_error_logging_in)
        {
            toast.error(message_for_login);
        }

        if (is_logged_in || user)
        {
            // console.log("User is logged in. Redirecting...");
            navigate( PATH_NAME.Dashboard );
        }

        return () => {
            // This will be included in the logout resetAuth
            // console.log("Login.js >> useEffect >> cleanup");
            // dispatch( resetLoginState() );
        };
    }, [user, is_error_logging_in, is_logged_in, message_for_login, navigate, dispatch]);

    const on_change = e => {
        set_form_data(prev_state => ({
            ...prev_state,
            [e.target.name]: e.target.value
        }))
    };

    const on_submit = e => {
        e.preventDefault();
        const user_data = { username, password };
        dispatch( login(user_data) );
    };

    return (
        is_logging_in ? <Spinner /> : (
            <>
            <div className="bright_bg">
                <div className="login_container">
                    <section className="headless_title">
                        {/* <h1><FaSignInAlt />Login</h1> */}
                        <h1>HOTS System</h1>
                        <h2>LOGIN</h2>
                    </section>
                    <section className="form">
                        <form onSubmit={on_submit}>
                            <div className="form_group">
                                <input type="text" className="form_control" id="username" name="username"
                                    value={username} placeholder="Enter your username" onChange={on_change} />
                            </div>

                            <div className="form_group">
                                <input type="password" className="form_control" id="password" name="password"
                                    value={password} placeholder="Enter your password" onChange={on_change} />
                            </div>

                            <div className="form_group">
                                <button type="submit" className="btn btn_block">SIGN IN</button>
                            </div>
                        </form>
                    </section>
                    <section className="headless_footnote">
                        <p>Need an account? Forgot your password?</p>
                        <p>Please contact a HOTS admin.</p>
                    </section>
                </div>
            </div>
            </>
        )
    )
};

export default Login;