import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderTeacher from "./HeaderTeacher";
import FooterTeacher from "./FooterTeacher";
import ReportWaitListTable from "./ReportWaitListTable";
import { can_access_page } from "../../app/user_utils";
import { PATH_NAME } from "../../app/constants";

const DashboardTeacher = () => {

    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Teacher_Dashboard) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    return (
        <>
            <div className="basic_bg font-sans">
                
                <HeaderTeacher />

                <div className="dashboard_container">

                <ReportWaitListTable />

                </div>

                <FooterTeacher />
            </div>
        </>
    );
}

export default DashboardTeacher;