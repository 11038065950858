import { FaUserGraduate } from "react-icons/fa";

const TabButtonStudents = () => {

    return (
        window.location.href.includes("/student_list") === true ? (
            <button className="btn_white_outline_highlighted">
                <FaUserGraduate />&nbsp;Students
            </button>
        ) : (
            <button className="btn_white_outline">
                <FaUserGraduate />&nbsp;Students
            </button>
        )
    );
};

export default TabButtonStudents;