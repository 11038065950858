import { useEffect, useState, useCallback } from "react";
import { FaChalkboard } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addLesson, resetAddLesson } from "../../../features/lessons/lessonSlice";
import { getTeachersByName, resetTeachers } from "../../../features/teachers/teacherSlice";
import { generateBlankReport, resetGenerateBlankReportState } from "../../../features/reports/reportSlice";
import Spinner from "../../Spinner";
import FooterAdmin from "../FooterAdmin";
import StudentSearchComponent from "../../StudentSearchComponent";
import { PATH_NAME, CLASS_LEVELS, TIME_SLOTS, ROOMS } from "../../../app/constants";
// import { PATH_NAME, CLASS_LEVELS, WEEK_NUMBERS_JUNIOR, WEEK_NUMBERS_SENIOR, TIME_SLOTS, ROOMS } from "../../../app/constants";
import { UI_STRING_LOADING_ALL } from "../../../app/strings";
import { getDate_MMDDYYYY_HKT } from "../../../app/utils";
import { can_access_page } from "../../../app/user_utils";

const AddLesson = () => {
    const [form_data, set_form_data] = useState({
        class_level: CLASS_LEVELS[0].value,
        time_of_day: TIME_SLOTS[0].start,
        end_time: TIME_SLOTS[0].end,
        // Set in backend
        // week_number: WEEK_NUMBERS_JUNIOR[0].value,
        room: ROOMS[0].value,
        center: ROOMS[0].center + " - Shop " + ROOMS[0].shop,
        selected_teacher: null,
        student_name: "",
        selected_students: [],
    });

    const { class_level, time_of_day, end_time, center, room, selected_teacher, selected_students } = form_data;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    // const { settings } = useSelector(state => state.settings);
    const { is_adding_lesson, is_added_lesson, new_lesson } = useSelector(state => state.lessons);

    const { teachers, is_success_reading_teachers } = useSelector(state => state.teachers);

    const [ formElement, setFormElement ] = useState(null);

    const { is_generated_blank_report, is_generating_blank_report } = useSelector(state => state.reports);
    const [ num_reports_generated, set_num_reports_generated ] = useState(0);

    const [search_teacher_query, set_search_teacher_query] = useState("");
    const [teacher_results, set_teacher_results] = useState([]);
    
    // const [week_options, set_week_options] = useState(WEEK_NUMBERS_JUNIOR);
    // const [is_loading_week_options, set_is_loading_week_options] = useState(false);

    // Redirect if not logged in or does not have access
    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Admin_Add_Lesson) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    const preventFormSubmitOnEnter = useCallback((event) => {
    
        if (event.key === "Enter")
        {
            // Prevent form submission
            event.preventDefault();
            return false;
        }
    }, []);

    useEffect(() => {
        // Attach event listener when formElement is set
        if (formElement)
        {
            formElement.addEventListener("keydown", preventFormSubmitOnEnter);

            // Remove event listener on cleanup
            return () => {
                formElement.removeEventListener("keydown", preventFormSubmitOnEnter);
            };
        }
    }, [formElement, preventFormSubmitOnEnter]);

    useEffect(() => {
        // When the component is mounted, set the form element
        setFormElement(document.getElementById("add_lesson_form"));
    }, []);

    useEffect(() => {
        if (is_added_lesson)
        {
            set_num_reports_generated(0);
            let i = 0;

            const report_data = { student: new_lesson.students[i],
                                    by_teacher: new_lesson.teacher,
                                    lesson: new_lesson,
                                    // Centre specific
                                    // week_number: isClassSenior( new_lesson.class_level ) ?
                                    //                             settings.current_senior_week_number : settings.current_junior_cycle_number,
                                };

            console.log(`AddLesson.js >> generate report - part 1, report_data ${i}: `, report_data);

            dispatch( generateBlankReport(report_data) );
        }

        // Only reset after blank reports have been generated
        // dispatch(resetLesson());
    }, [user, is_added_lesson, new_lesson, set_num_reports_generated, navigate, dispatch]);

    useEffect(() => {
        if (is_generated_blank_report)
        {
            dispatch( resetGenerateBlankReportState() );

            let i = num_reports_generated + 1;

            // Increment the number of reports generated
            set_num_reports_generated(num_reports_generated + 1);

            if (i < new_lesson.students.length)
            {
                const report_data = { student: new_lesson.students[i],
                                        by_teacher: new_lesson.teacher,
                                        lesson: new_lesson,
                                        // week_number: new_lesson.week_number
                                    };

                // console.log(`AddLesson.js >> generate report - part 2, report_data ${i}: `, report_data);

                dispatch( generateBlankReport(report_data) );
            }
            // All reports have been generated
            else
            {
                dispatch( resetAddLesson() );

                navigate( PATH_NAME.Admin_Dashboard );
            }
        }
    }, [is_generated_blank_report, new_lesson, num_reports_generated, set_num_reports_generated, navigate, dispatch]);

    useEffect(() => {
        
        if (is_success_reading_teachers && teachers)
        {
            set_teacher_results(teachers);
        }

        // This represents the unmount function,
        // in which it's a good idea to reset to prevent memory leaks.
        return () => {
            dispatch(resetTeachers());
        };

    }, [teachers, is_success_reading_teachers, dispatch]);

    const handle_class_level_change = (event) => {

        // const selected_level = event.target.value;
        const selected_level = CLASS_LEVELS.find(level => level.value === event.target.value);

        // set_selected_level(curr_level.value);

        // set_is_loading_week_options(true); // Start loading before options update

        // setTimeout(() => {
        //     if (selected_level.type === "Junior")
        //     {
        //         set_week_options( WEEK_NUMBERS_JUNIOR );
        //     }
        //     else
        //     {
        //         set_week_options( WEEK_NUMBERS_SENIOR );
        //     }

        //     set_is_loading_week_options(false); // End loading after options update

        // }, 1000); // Simulate a delay for updating options
        
        set_form_data((prev_data) => ({
            ...prev_data,
            class_level: selected_level.value,
        }));
    };

    const handle_time_change = (event) => {

        // const selected_time = event.target.value;
        const selected_time = TIME_SLOTS.find(slot => slot.value === event.target.value);

        set_form_data((prev_data) => ({
            ...prev_data,
            time_of_day: selected_time.start,
            end_time: selected_time.end,
        }));
    };

    // const handle_week_number_change = (event) => {
    //     const selected_week = event.target.value;
    //     set_form_data((prev_data) => ({
    //         ...prev_data,
    //         week_number: selected_week,
    //     }));
    // };

    // Handle input change for teacher search
    const handle_teacher_search = async (e) => {
        const query = e.target.value;
        set_search_teacher_query(query);

        if (query.length > 0)
        {
            try
            {
                // console.log("AddLesson.js >> handle_teacher_search :: query: ", query);
                dispatch(getTeachersByName(query));  
            }
            catch (error)
            {
                console.error("Failed to fetch teachers", error);
            }
        }
        else
        {
            set_teacher_results([]);
        }
    };

    // Handle adding teacher to the selected list
    const handle_add_teacher = (teacher) => {
        // Only one teacher can be selected
        if (form_data.selected_teacher)
        {
            return;
        }

        if (!form_data.selected_teacher)
        {
            set_form_data({
                ...form_data,
                selected_teacher: teacher
            });
        }
    };

    // Handle removing teacher from the selected list
    const handle_remove_teacher = () => {
        set_form_data({
            ...form_data,
            selected_teacher: null
        });
    };

    const handle_room_change = (event) => {
        const selected_room = event.target.value;
        set_form_data((prev_data) => ({
            ...prev_data,
            room: selected_room,
            center: ROOMS.find(room => room.value === selected_room).center + " - Shop " + ROOMS.find(room => room.value === selected_room).shop,
        }));
    };

    // Handle adding student to the selected list
    const handle_add_student = (student) => {
        if (!form_data.selected_students.find(s => s.student_id === student.student_id))
        {
            set_form_data({
                ...form_data,
                selected_students: [...form_data.selected_students, student]
            });
        }
    };

    // Handle removing student from the selected list
    const handle_remove_student = (studentId) => {
        set_form_data({
            ...form_data,
            selected_students: form_data.selected_students.filter(s => s.student_id !== studentId)
        });
    };

    const submit_form = e => {
        e.preventDefault();

        if (!selected_students || selected_students.length === 0)
        {
            toast.error("Please add students.");
        }
        else if (!selected_teacher)
        {
            toast.error("Please add a teacher.");
        }
        else
        {
            const lesson_data = { class_level,
                                    // date: new Date(), // deprecated in v1.0
                                    date_string: getDate_MMDDYYYY_HKT(new Date()),
                                    time_of_day,
                                    end_time,
                                    // week_number,
                                    center,
                                    room,
                                    teacher: selected_teacher,
                                    students: selected_students };

            // console.log("AddLesson.js >> on_submit, lesson_data: ", lesson_data);

            dispatch( addLesson(lesson_data) );
        }
    };

    if (is_adding_lesson)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Adding_Lesson }/>; // Handle loading state
    }   
    
    if (is_generating_blank_report)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Generating_Reports }/>; // Handle loading state
    }

    return (
        (
            <>
            <div className="bg-gradient-to-b from-hots-green-1 to-hots-green-2 ... text-center">
                <div className="login_container">
                    <section className="headless_title">
                        <h1 className="flex items-center justify-center"><FaChalkboard className="mr-8"/>Add a Class & Take Attendance</h1>
                        {/* <h2>Create an account for a Teacher</h2> */}
                    </section>
                    
                    <section className="form">
                        <form id="add_lesson_form" onKeyDown={preventFormSubmitOnEnter}>

                            <div className="form_group">
                                <div className="mb-8">
                                    <label className="text-gray-700 font-bold text-xl">Today's Date:</label>
                                    <label className="text-gray-200 font-medium text-xl">{getDate_MMDDYYYY_HKT( new Date() )}</label>
                                </div>

                                <label className="text-gray-700 font-bold text-xl">Select Time Slot:</label>

                                <select
                                    className="form_control"
                                    name="time"
                                    id="time"
                                    onChange={handle_time_change}
                                    defaultValue={TIME_SLOTS[0].value} // Default to the first time slot
                                >
                                    {TIME_SLOTS.map((slot, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={slot.value}
                                        >
                                            {slot.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Class Level:</label>

                                <select
                                    className="form_control"
                                    name="level"
                                    id="level"
                                    onChange={handle_class_level_change}
                                    defaultValue={CLASS_LEVELS[0].value} // Default to the first level
                                >
                                    {CLASS_LEVELS.map((level, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={level.value}
                                        >
                                            {level.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Week Number:</label>
                                <select
                                    className="form_control"
                                    name="week_number"
                                    id="week_number"
                                    onChange={handle_week_number_change}
                                    disabled={is_loading_week_options} // Disable when loading
                                >
                                    {is_loading_week_options ? (
                                        <option>Please wait...</option> // Display "Please wait..." while loading
                                    ) : (
                                        week_options.map((week, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={week.value}>
                                            {week.label}
                                        </option>
                                        ))
                                    )}
                                </select>
                            </div> */}

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Classroom:</label>

                                <select
                                    className="form_control"
                                    name="room"
                                    id="room"
                                    onChange={handle_room_change}
                                    defaultValue={ROOMS[0].value} // Default to the first room
                                >
                                    {ROOMS.map((room, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={room.value}
                                        >
                                            {room.label + " (Shop " + room.shop + ")"}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <hr className="mt-8 mb-8"></hr>

                            {/* Selected Teacher Field */}
                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Teacher for this Class:</label>
                                <ul>
                                    {selected_teacher ? (
                                        <li className="text-white text-left font-bold text-xl">
                                            <button type="button" onClick={() => handle_remove_teacher()}>
                                                [-] &nbsp; {selected_teacher.display_name}
                                            </button>
                                        </li>
                                        ) : (
                                            <></>
                                    )}
                                </ul>
                            </div>

                            {/* Teacher Search Field */}
                            <div className="form_group">
                                {/* <label className="text-gray-700 font-bold text-xl">Search Teacher by Name:</label> */}
                                <input
                                    type="text"
                                    className="form_control"
                                    placeholder="Enter teacher's name to search"
                                    value={search_teacher_query}
                                    onChange={handle_teacher_search}
                                />
                                <ul className="search_results">
                                    {teacher_results.map((teacher) => (
                                        <li className="text-gray-800 text-left text-xl" key={teacher.username}>
                                            <button type="button" onClick={() => handle_add_teacher(teacher)}>
                                                [+] &nbsp; {teacher.display_name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <hr className="mt-8 mb-8"></hr>

                            {/* Selected Students List */}
                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Students Present in this Class:</label>
                                <ul>
                                    {form_data.selected_students.map((student) => (
                                        <li className="text-white text-left text-xl" key={student.student_id}>
                                            <button type="button" onClick={() => handle_remove_student(student.student_id)}>
                                                [-] &nbsp; {student.student_name + " (" + student.student_id + ")"}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <StudentSearchComponent
                                is_multiple={ true }
                                on_result_click={ handle_add_student }
                            />

                            <div className="form_group mt-12">
                                <button className="btn btn_block" onClick={submit_form}>Add to System</button>
                            </div>
                        </form>
                    </section>
                </div>

                <FooterAdmin />
            </div>
            </>
        )
    )
};

export default AddLesson;