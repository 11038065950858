import { PATH_NAME, DAY_OF_WEEK_NUM, DAY_OF_WEEK_NUM_SHORT } from "./constants";

export function is_summary_page( location_pathname )
{
  // User normally visits this page via direct link, instead of via navigate()
  // so pathname is "/summary/{random}" instead of "/summary"
  // Matches "/summary/{random}" where random is alphanumeric and may contain underscores
  const link_pattern = new RegExp(`^${PATH_NAME.Parent_View_Summary}/[a-zA-Z0-9_]+$`);

  const is_summary_link = link_pattern.test(location_pathname);

  // console.log("utils.js >> is_summary_page :: is_summary_link: ", is_summary_link);
  return is_summary_link;
};

export function getFullDate( input_date )
{
  // const today = new Date();
  const the_date = new Date( input_date );  // Assuming "input_date" is in ISO format or a valid date string
  const month = the_date.toLocaleString("en-us", { month: "long" });
  const year = the_date.getFullYear();
  const date = the_date.getDate();
  const day_of_week = the_date.getDay();
  return `${DAY_OF_WEEK_NUM[day_of_week]}, ${date} ${month}, ${year}`;
}

export function getWeekNumber_Short( mm_dd_yyyy )
{
  const the_date = new Date( mm_dd_yyyy.substring(6, 10), mm_dd_yyyy.substring(0, 2) - 1, mm_dd_yyyy.substring(3, 5) );

  // This doesn't work in Safari for some reason
  // const the_date = new Date( mm_dd_yyyy );

  // This doesn't work in Safari for some reason
  return DAY_OF_WEEK_NUM_SHORT[ the_date.getDay() ];
}

export function getDateInYyyyMmDd( the_date )
{
  return the_date.toISOString().split("T")[0];
}

export function getDateInMmDdYyyy( input_date )
{
  const the_date = new Date( input_date );  // Assuming "input_date" is in ISO format or a valid date string
  const year = the_date.getFullYear();
  const month = String(the_date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
  const day = String(the_date.getDate()).padStart(2, '0');

  return `${month}-${day}-${year}`;
}

// export function getDate_MMDDYYYY_HKT( input_date )
// {
//   const the_date = new Date( input_date );  // Assuming "input_date" is in ISO format or a valid date string

//   console.log("getDate_MMDDYYYY_HKT :: the_date: ", the_date);
  
//   const utc_date = new Date(Date.UTC(
//       the_date.getUTCFullYear(),
//       the_date.getUTCMonth(),
//       the_date.getUTCDate(),
//       the_date.getUTCHours(),
//       the_date.getUTCMinutes(),
//       the_date.getUTCSeconds()
//   ));

//   return utc_date.toLocaleString("en-US", {timeZone: "Asia/Hong_Kong", month: "2-digit", day: "2-digit", year: "numeric"}).split("/").join("-");
// }

export function getDate_MMDDYYYY_HKT(input_date)
{
  let the_date;

  // Check if input_date is a string
  if (typeof input_date === 'string' || input_date instanceof String)
  {
    // Attempt to parse ISO 8601 format
    the_date = new Date(input_date);

    if (isNaN(the_date.getTime()))
    {
      // If parsing failed, try replacing dashes with slashes
      const adjusted_date_string = input_date.replace(/-/g, '/');
      the_date = new Date(adjusted_date_string);

      if (isNaN(the_date.getTime()))
      {
        // If still invalid, parse manually
        const dateParts = input_date.match(/^(\d{4})-(\d{2})-(\d{2})$/);

        if (dateParts)
        {
          const year = parseInt(dateParts[1], 10);
          const month = parseInt(dateParts[2], 10) - 1; // Months are zero-indexed
          const day = parseInt(dateParts[3], 10);

          the_date = new Date(year, month, day);
        }
        else
        {
          console.error('Invalid date format:', input_date);
          return 'Invalid Date';
        }
      }
    }
  } else {
    // If input_date is not a string, assume it's a timestamp or Date object
    the_date = new Date(input_date);
  }

  if (isNaN(the_date.getTime())) {
    console.error('Invalid date after parsing:', input_date);
    return 'Invalid Date';
  }

  // console.log('getDate_MMDDYYYY_HKT :: the_date:', the_date);

  // Create a date in UTC
  const utc_date = new Date(Date.UTC(
    the_date.getFullYear(),
    the_date.getMonth(),
    the_date.getDate(),
    the_date.getHours(),
    the_date.getMinutes(),
    the_date.getSeconds()
  ));

  // Format the date in "MM-DD-YYYY" for the "Asia/Hong_Kong" timezone
  const formattedDate = utc_date.toLocaleString('en-US', {
    timeZone: 'Asia/Hong_Kong',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  }).split('/').join('-');

  return formattedDate;
}

export function isClassSenior( class_level )
{
  // console.log("isClassSenior: class_level = ", class_level);

  return (class_level === "HEEP" || class_level === "HE L1" || class_level === "HE L2" || class_level === "HI");
}