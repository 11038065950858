import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderTeacher from "./HeaderTeacher";
import FooterTeacher from "./FooterTeacher";
import StudentSearchComponent from "../StudentSearchComponent";
import { can_access_page } from "../../app/user_utils";
import { PATH_NAME } from "../../app/constants";

const MyStudentsPage = () => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Teacher_My_Students) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    const go_to_next_page = (student) => {

        if (student && student.student_id && student.student_name)
        {
            navigate(PATH_NAME.Teacher_My_Student_Reports + "/" + student.student_id + "/" + student.student_name);
        }
    };

    return (
        user && user.role === "teacher" &&
        (<>
            <div className="basic_bg font-sans">
                
                <HeaderTeacher />

                <div className="dashboard_container">
                    <section className="heading">
                        <h1>Student Reports</h1>
                        
                    </section>

                    <div className="p-4 tracking-wider">

                        <StudentSearchComponent
                            is_multiple={ false }
                            on_result_click={ go_to_next_page }
                        />
                        
                    </div>
                    
                </div>

                <FooterTeacher />
            </div>
        </>)
    );
}

export default MyStudentsPage;