import React, { useState } from "react";

const VideoViewer = ({ videoUrls }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);

  const openModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideoUrl(null);
  };

  return (
    <>
      <div className="video_thumbnails my-10">
        {videoUrls.map((videoUrl, index) => (
          <div key={index} className="video_thumbnail">
            <video
              src={videoUrl}
              width="100"
              onClick={() => openModal(videoUrl)}
              className="cursor-pointer"
            />
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="video_modal_overlay" onClick={closeModal}>
          <div className="video_modal_content" onClick={(e) => e.stopPropagation()}>
            <video
              src={selectedVideoUrl}
              controls
              autoPlay
              className="video_modal"
            />
            <button onClick={closeModal} className="video_modal_close">
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoViewer;