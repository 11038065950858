import axios from "axios";
import { MAIN_URL } from "../../app/config";

const API_URL = MAIN_URL + "/api/reports/"

const createReport = async (report_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.post(API_URL, report_data, config);
    return response.data;
}

const generateBlankReport = async (report_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("reportService.js >> generateBlankReport :: report_data: ", report_data);

    const response = await axios.post(API_URL + "generate/", report_data, config);
    return response.data;
}

const getReports = async token => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config);
    return response.data;
}

const getPendingReportsByTeacher = async (teacher_username, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("reportService.js >> getPendingReportsByTeacher :: username = ", teacher_username);

    const response = await axios.get(API_URL + "pending/teacher?username=" + teacher_username, config);
    return response.data;
}

const getAllPendingReports = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("reportService.js >> getAllPendingReports ", config);

    const response = await axios.get(API_URL + "pending/all", config);
    return response.data;
}

const getReportsByStudent = async (student_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL + "student/" + student_id, config);
    return response.data;
}

const fetchReportById = async (report_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL + report_id, config);
    return response.data;
}

const updateReport = async (report_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("reportService.js >> updateReport :: report_data = ", report_data);
    const response = await axios.put(API_URL + report_data._id, report_data, config);
    return response.data;
}

const deleteReport = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.delete(API_URL + id, config);
    return response.data;
}

const reportService = { createReport,
                        generateBlankReport,
                        getReports,
                        getPendingReportsByTeacher,
                        getAllPendingReports,
                        getReportsByStudent,
                        fetchReportById,
                        updateReport,
                        deleteReport };

export default reportService;