import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderAdmin from "../HeaderAdmin";
import FooterAdmin from "../FooterAdmin";
import StudentSearchComponent from "../../StudentSearchComponent";
import { can_access_page } from "../../../app/user_utils";
import { PATH_NAME } from "../../../app/constants";

const ParentSearchPage = () => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Admin_Parent_List) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    const go_to_next_page = (student) => {

        if (student && student.student_id && student.student_name)
        {
            navigate(PATH_NAME.Admin_Student_Summaries + "/" + student.student_id + "/" + student.student_name);
        }
    };

    return (
        <>
            <div className="basic_bg font-sans">
                
                <HeaderAdmin />

                <div className="dashboard_container">
                    <section className="heading">
                        <h1>Student Summaries</h1>
                        
                    </section>

                    <div className="p-4 tracking-wider">

                        <StudentSearchComponent
                            is_multiple={ false }
                            on_result_click={ go_to_next_page }
                        />
                        
                    </div>
                    
                </div>

                <FooterAdmin />
            </div>
        </>
    );
}

export default ParentSearchPage;