import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import { FaPlus } from "react-icons/fa";
import HeaderAdmin from "../HeaderAdmin";
import FooterAdmin from "../FooterAdmin";
import LessonTable from "../timetable/LessonTable";
import { getFullDate, getDateInMmDdYyyy } from "../../../app/utils";
import { can_access_page } from "../../../app/user_utils";
import { PATH_NAME } from "../../../app/constants";

const DayPage = () => {

    const { the_date } = useParams();

    const input_date = the_date ? the_date : null;

    const [ formatted_date ] = useState( getDateInMmDdYyyy(input_date) );
    // const formatted_date = getDate_MMDDYYYY_HKT(input_date);

    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    // function format_date(input_date)
    // {
    //     const date = new Date(input_date);
        
    //     // Get the month, day, and year
    //     let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    //     let day = String(date.getDate()).padStart(2, '0');
    //     let year = date.getFullYear();
        
    //     // console.log("ClassSearchComponent.js >> format_date :: formatted date: ", `${month}-${day}-${year}`);

    //     // Format as mm-dd-yyyy
    //     return `${month}-${day}-${year}`;
    // }

    useEffect(() => {

        if (can_access_page(user, PATH_NAME.Admin_Day_Lessons) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    return (
        <>
            <div className="basic_bg font-sans">
                
                <HeaderAdmin />

                <div className="dashboard_container">
                    <section className="heading">
                        <div className="px-6 py-3 text-center font-extrabold text-white">{ getFullDate( input_date ) }</div>
                        <h1>Classes</h1>
                    </section>

                    <LessonTable the_date={ formatted_date }/>
                    {/* This causes extra re-rendering */}
                    {/* <LessonTable the_teacher={[]} the_date={ formatted_date }/> */}
                </div>

                <FooterAdmin />
            </div>
        </>
    );
}

export default DayPage;