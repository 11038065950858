import { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaSignOutAlt, FaBell, FaHome } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import TabButtonStudents from "./TabButtonStudents";
import TabButtonParents from "./TabButtonParents";
import TabButtonTimetable from "./TabButtonTimetable";
import TabButtonClasses from "./TabButtonClasses";
import { run_logout } from "../../app/user_utils";
import { PATH_NAME } from "../../app/constants";

const HeaderAdmin = () => {

    const { user } = useSelector((state) => state.auth);
    
    const { pending_reports } = useSelector((state) => state.reports);
    const { pending_summaries } = useSelector((state) => state.summaries);

    const [ total_notifications, set_total_notifications ] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        let num_pending_reports = 0;
        let num_pending_summaries = 0;

        if (pending_reports)
        {
            num_pending_reports = pending_reports.length;
        }

        if (pending_summaries)
        {
            num_pending_summaries = pending_summaries.length;
        }
        
        set_total_notifications(num_pending_reports + num_pending_summaries);

    }, [pending_reports, pending_summaries]);

    const handle_logout = useCallback(() => {

        run_logout(dispatch, navigate);

    }, [dispatch, navigate]);

    return (
        <header className="bg-basic">
            <div className="container mx-auto p-4">
                <div className="header_top flex justify-between items-center mt-4 mb-2">
                    <div className="header_top_left flex space-x-4">
                        <Link to={ PATH_NAME.Admin_Dashboard } className="header_logo_txt flex items-center">
                            <FaHome className="mr-1" /> HOTS System
                        </Link>
                        <Link to={ PATH_NAME.Admin_Notifications } className="flex items-center">
                            {/* <span className="header_alert_icon"><FaBell className="mr-1" /></span><span className="header_alert_txt">1</span> */}
                            <div className="relative inline-block">
                                <span className="header_alert_icon">
                                    <FaBell className="mr-1 text-gray-500 hover:text-gray-700" size={24} />
                                </span>
                                {
                                    total_notifications > 0 &&
                                    (<span className="header_alert_txt">{total_notifications}</span>)
                                }
                            </div>
                        </Link>
                    </div>
                    <div className="header_top_right">
                        <button onClick={handle_logout}>
                            <span className="flex items-center">
                                <strong className="flex items-center"><FaSignOutAlt className="mr-1" /> Logout</strong>
                                <span className="flex items-center">&nbsp; ({user && user.username })</span>
                            </span>
                        </button>
                    </div>
                </div>
                {/* <div className="header_bottom grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-2 p-4"> */}
                <div className="header_bottom flex flex-wrap justify-between gap-2 p-3">
                        
                    <Link to={ PATH_NAME.Admin_Dashboard }>
                        <TabButtonTimetable />
                    </Link>

                    <Link to={ PATH_NAME.Admin_Class_List }>
                        <TabButtonClasses />
                    </Link>
                    
                    <Link to={ PATH_NAME.Admin_Student_List }>
                        <TabButtonStudents />
                    </Link>
                    
                    <Link to={ PATH_NAME.Admin_Parent_List }>
                        <TabButtonParents />  
                    </Link>
                </div>
            </div>
        </header>
    );

}

export default HeaderAdmin;