import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import HeaderAdmin from "./HeaderAdmin";
import FooterAdmin from "./FooterAdmin";
import LessonTable from "./timetable/LessonTable";
import { FaPlus } from "react-icons/fa";
import { getFullDate, getDateInMmDdYyyy } from "../../app/utils";
import { PATH_NAME } from "../../app/constants";
import { can_access_page } from "../../app/user_utils";

const DashboardAdmin = () => {

    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    const [ date_now ] = useState( getDateInMmDdYyyy(new Date()) );

    const [ should_render, set_should_render ] = useState(false);

    useEffect(() => {

        // Now checked in App.js
        // if (!user)
        // {
        //     navigate( PATH_NAME.Login );
        // }

        if (can_access_page(user, PATH_NAME.Admin_Dashboard) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }

    }, [user, navigate]);

    useEffect(() => {

        if (user && user.role === "admin")
        {
            // console.log("DashboardAdmin.js >> useEffect :: should render...");

            set_should_render(true);
        }
    
    // Dependency ensures that the effect runs whenever user changes
    // but still keeps the desired behavior for rendering the component once. 
    }, [user]); 


    return (
        should_render &&
        (<>
            <div className="basic_bg font-sans">
                
                <HeaderAdmin />

                <div className="dashboard_container">
                <section className="heading">
                    <div className="px-6 py-3 text-center font-extrabold text-white">{getFullDate( new Date() )}</div>
                    <h1>Today's Classes</h1>

                    <Link to={ PATH_NAME.Admin_Add_Lesson } className="flex justify-center mt-8 mb-16">
                        <button className="btn w-5/6"><FaPlus />&nbsp;Add a Class to Take Attendance</button>
                    </Link>
                    
                </section>
                
                <LessonTable the_date={ date_now }/>

                </div>

                <FooterAdmin />
            </div>
        </>)
    );
}

export default React.memo(DashboardAdmin);