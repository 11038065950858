import { FaUserGraduate } from "react-icons/fa";

const TabButtonStudentsForTeacher = () => {

    return (
        window.location.href.includes("/my_students") === true ? (
            <button className="btn_white_outline_highlighted">
                <FaUserGraduate />&nbsp;My Students
            </button>
        ) : (
            <button className="btn_white_outline">
                <FaUserGraduate />&nbsp;My Students
            </button>
        )
    );
};

export default TabButtonStudentsForTeacher;