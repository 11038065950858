import React, { useEffect, useRef, useCallback, useState } from "react";
import { Chart } from "chart.js/auto";
import { CHART_DATA_RED, CHART_SETTINGS_AVERAGES, CHART_SETTINGS_SCORES } from "./ChartDataRed";

const ChartViewerRed = ({ chart_data }) => {

  const chartRefs = useRef([]); // Array of canvas refs
  const chartInstances = useRef([]); // Array of chart instance refs
  
  const [ curr_chart_data, set_curr_chart_data ] = useState([]);

  // Function to calculate the average for the overall chart
  // y = (Σ y) / n, where y is the average value of all category scores and n is the number of categories
  // x = number of weeks
  const calculateOverallAverages = useCallback((content_array) => {

    // const numWeeks = content_array.length;

    const filtered_content_array = extractUnderscorePrefixedEntries(content_array);

    // console.log("ChartViewerRed.js >> calculateOverallAverages >> filtered_content_array: ", filtered_content_array);

    // Extract keys from the content field of the first entry
    // Assuming all weeks have the same categories
    const categories = Object.keys(filtered_content_array[0].content);

    // Guard clause to avoid division by zero
    // but the math will be wrong if there are no categories
    const num_categories = categories.length > 0 ? categories.length : 1;

    // Iterate over each week in fetched_summary
    const weekly_averages = filtered_content_array.map((week) => {

      // const categories = Object.keys(week.content); // Extract the category keys for this week
      // const numCategories = categories.length;

      // Calculate the total for all categories in this week
      const total = categories.reduce((acc, category) => {
        return acc + parseInt(week.content[category], 10); // Sum up values for this week
      }, 0);

      // Return the average for this week (total / number of categories)
      return total / num_categories;
    });

    // console.log("ChartViewerRed.js >> calculateOverallAverages >> weekly_averages: ", weekly_averages);

    // Create an array of size categories.length
    // Array of arrays, each representing a category's weekly scores
    const category_scores = categories.map((category) => {

      // For each category, create an array of size numWeeks, mapping that category's score for each week
      return filtered_content_array.map((week) => {
        return parseInt(week.content[category], 10); // Map each week's category score
      });
    });

    // console.log("ChartViewerRed.js >> calculateOverallAverages >> category_scores: ", category_scores);

    return {weekly_averages, category_scores}; // Return the averages and category scores

  }, []); // Dependency array passed

  // Function to extract only keys prefixed with "_"
  // (because in report content, metrics are prefixed with "_")
  const extractUnderscorePrefixedEntries = (input_content) => {
    return input_content.map((entry) => {

      const filtered_content = {};
      
      // Filter the keys that start with "_"
      Object.keys(entry.content).forEach((key) => {
        if (key.startsWith('_')) {
          filtered_content[key] = entry.content[key];
        }
      });
      
      return { ...entry, content: filtered_content }; // Return the entry with filtered content
    });
  };

  useEffect(() => {

    if (!chart_data || chart_data.length === 0) return; // Guard clause to wait until chartData is available

    set_curr_chart_data( chart_data );

    return () => {
      set_curr_chart_data([]);
    }

  }, [chart_data]);

  useEffect(() => {

    if (!curr_chart_data || curr_chart_data.length === 0) return; // Guard clause to wait until chartData is available

    // console.log("ChartViewerRed.js >> useEffect >> dataset length: ", curr_chart_data.length);

    //////////////////////////////////////////////////////////////
    //------------------------------------------------------------
    // Prepare chart data
    //------------------------------------------------------------
    //////////////////////////////////////////////////////////////

    const numWeeks = curr_chart_data.length;

    const week_labels = Array.from({ length: numWeeks }, (_, i) => `Week ${i + 1}`);

    // Chart 0 - Overall Averages
    const overall_data = calculateOverallAverages( curr_chart_data ).weekly_averages;

    const category_scores = calculateOverallAverages( curr_chart_data ).category_scores;
    // console.log("ChartViewerRed.js >> useEffect >> overall_data: ", overall_data);

    //////////////////////////////////////////////////////////////
    //------------------------------------------------------------
    // Prepare canvas and chart instances
    //------------------------------------------------------------
    //////////////////////////////////////////////////////////////

    // Capture the initial value of chartInstances.current at the beginning of the effect
    const existingChartInstances = chartInstances.current;

    const fixed_charts = CHART_DATA_RED;

    // Loop through chart_data to create and destroy charts dynamically
    fixed_charts.forEach((the_set, index) => {
      const ctx = chartRefs.current[index].getContext("2d");

      // Destroy existing chart instance if it exists
      if (existingChartInstances[index]) {
        existingChartInstances[index].destroy();
      }

      // Create a new chart instance for each canvas
      // Data is loaded from a combination of predefined constants and chart_data
      chartInstances.current[index] = new Chart(ctx, {
        title: the_set.title,
        type: the_set.type,
        data: {
          labels: week_labels,
          datasets: [
            {
              label: the_set.label,
              // 0 - Overall Averages, 1 - Category Scores 1, 2 - Category Scores 2, etc.
              data: index === 0 ? overall_data : category_scores[index - 1],
              backgroundColor: the_set.backgroundColor,
              borderColor: the_set.borderColor,
              borderWidth: index === 0 ? CHART_SETTINGS_AVERAGES.borderWidth : CHART_SETTINGS_SCORES.borderWidth,
            },
          ],
        },
        options: index === 0 ? CHART_SETTINGS_AVERAGES.options : CHART_SETTINGS_SCORES.options,
      });
    });

    // Cleanup: Capture the current instances in a local variable
    const currentInstances = chartInstances.current;

    // Cleanup: Destroy all charts when component unmounts
    return () => {
        currentInstances.forEach((chart) => {
            if (chart) {
            chart.destroy();
            }
        });
    };
  }, [curr_chart_data, // Re-run effect if chartData changes
      calculateOverallAverages,
  ]);

  return (
    <>
    {
        (!curr_chart_data || curr_chart_data.length === 0) &&
        <div className="text-gray-100 text-lg font-semibold mb-4">
            Loading charts, please wait...
        </div>
    }

    {
        (curr_chart_data && curr_chart_data.length > 0) &&
        <>
        {/* Overall Chart */}
        <div className="bg-transparent p-6 my-16">
            <h3 className="text-gray-100 text-lg font-semibold mb-4">{CHART_DATA_RED[0].title}</h3>
            <canvas
                ref={(el) => (chartRefs.current[0] = el)} // Reference for the first chart
                width="1200"
                height="800"
            ></canvas>
        </div>

        {/* Render the rest of the charts using map starting from index 1 */}
        {/* Double or single column depending upon screen width */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

            {CHART_DATA_RED.slice(1).map((data, index) => (

                <div key={index + 1} className="bg-white rounded-xl p-6 shadow-md md:col-span-1">
                <h3 className="text-gray-700 font-semibold mb-4">{data.title}</h3>
                <canvas
                    ref={(el) => (chartRefs.current[index + 1] = el)} // Adjust index for chartRefs
                    width="320"
                    height="240"
                    className="pb-8" // To avoid cutoff at the bottom
                ></canvas>
                </div>
            ))}

        </div>
        </>
    }
    </>
  );
};

export default ChartViewerRed;