import axios from "axios";
import { MAIN_URL } from "../../app/config";

const API_URL = MAIN_URL + "/api/admin_alerts/"

const createAdminAlert = async (alert_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.post(API_URL, alert_data, config);
    return response.data;
}

const getOpenAdminAlerts = async token => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("adminAlertService.js >> getOpenAdminAlerts: token = " + token);

    const response = await axios.get(API_URL, config);

    // console.log("adminAlertService.js >> getOpenAdminAlerts: response.data = " + response.data);

    return response.data;
}

const closeAdminAlert = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("adminAlertService.js >> closeAdminAlert: id = " + id + "; token = " + token);
    // const response = await axios.delete(API_URL + id, config);
    const response = await axios.post(API_URL + id + "/close", config);
    return response.data;
}

const adminAlertService = { createAdminAlert, getOpenAdminAlerts, closeAdminAlert };

export default adminAlertService;