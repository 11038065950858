import { useLocation } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import { PATH_NAME } from "../../app/constants";

const TabButtonTimetable = () => {

    const location = useLocation();

    return (
        location.pathname === PATH_NAME.Admin_Dashboard ? (
            <button className="btn_white_outline_highlighted">
                <FaCalendarAlt />&nbsp;Timetable
            </button>
        ) : (
            <button className="btn_white_outline">
                <FaCalendarAlt />&nbsp;Timetable
            </button>
        )
    );
};

export default TabButtonTimetable;