import axios from "axios";
import { MAIN_URL } from "../../app/config";

const API_URL = MAIN_URL + "/api/lessons/"

const addLesson = async (lesson_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("lessonService.js >> addLesson :: lesson_data: ", lesson_data);

    const response = await axios.post(API_URL, lesson_data, config);
    return response.data;
}

const getLessons = async token => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config);
    return response.data;
}

const getLessonsByDate = async (date_string, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("lessonService.js >> getLessonsByDate :: date_string = " + date_string);
    const response = await axios.get(API_URL + "date/" + date_string, config);
    return response.data;
}

const lessonService = { addLesson, getLessons, getLessonsByDate };

export default lessonService;