import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import fileService from "./fileService";

const initialState_VoiceMessage = {
    download_response: null,
    is_downloading_voice_message: false,
    is_downloaded_voice_message: false,
    is_error_downloading_voice_message: false,
    message_for_voice_message_download: "",
    // download_voice_messages_progress: 0,
};

const initialState = {
    ...initialState_VoiceMessage,
};

export const downloadVoiceMessage = createAsyncThunk(
    "files/download_voice_message",
    async (media_url, thunkAPI) => {
        try
        {
            console.log("fileSlice.js >> downloadVoiceMessages :: media_url = ", media_url);

            return await fileService.downloadVoiceMessage(media_url, thunkAPI);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const fileSlice = createSlice({
    name: "files",
    initialState,
    reducers: {
        resetFiles: state => initialState,
        resetDownloadVoiceMessage: state => initialState_VoiceMessage,
        // setUploadVoiceMessagesProgress: (state, action) => {
        //     state.upload_voice_messages_progress = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(downloadVoiceMessage.pending, state => {
                state.is_downloading_voice_message = true;
                state.is_downloaded_voice_message = false;
                state.is_error_downloading_voice_message = false;
                // state.download_voice_message_progress = 0;
            })
            .addCase(downloadVoiceMessage.fulfilled, (state, action) => {
                state.is_downloading_voice_message = false;
                state.is_downloaded_voice_message = true;
                state.download_response = action.payload;
                // state.download_voice_messages_progress = 100;
            })
            .addCase(downloadVoiceMessage.rejected, (state, action) => {
                state.is_downloading_voice_message = false;
                state.is_error_downloading_voice_message = true;
                state.message_for_voice_message_download = action.payload;
                // state.download_voice_messages_progress = 0;
            })
    }
});

export const { resetFiles,
            resetDownloadVoiceMessages,
            // setUploadVoiceMessagesProgress,
        } = fileSlice.actions;
export default fileSlice.reducer;