import { useEffect, useState, useCallback } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addStudent, resetAddStudent } from "../../../features/students/studentSlice";
import Spinner from "../../Spinner";
import FooterAdmin from "../FooterAdmin";
import { can_access_page } from "../../../app/user_utils";
import { PATH_NAME } from "../../../app/constants";

const AddStudent = () => {
    const [form_data, set_form_data] = useState({
        student_name: "",
        student_id: "",
        preferred_name: "",
    });

    const { student_name, student_id, preferred_name } = form_data;

    const [ formElement, setFormElement ] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { new_student, is_adding_student, is_added_student, is_error_adding_student, message_adding_student } = useSelector(state => state.students);

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Admin_Add_Student) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    useEffect(() => {

        // The return statement inside the useEffect function is used to define the cleanup function.
        // This cleanup function is triggered when the component is about to unmount,
        // which effectively catches the "leaving the page" scenario.
        return () => {
            dispatch( resetAddStudent() );
        }
    }, [dispatch]);

    useEffect(() => {

        if (is_added_student)
        {
            // Reset the form
            set_form_data({
                student_name: "",
                student_id: "",
                preferred_name: "",
            });
        }

    }, [is_added_student, set_form_data]);
    
    const preventFormSubmitOnEnter = useCallback((event) => {
    
        if (event.key === "Enter")
        {
            // Prevent form submission
            event.preventDefault();
            return false;
        }
    }, []);

    useEffect(() => {
        // Attach event listener when formElement is set
        if (formElement)
        {
            formElement.addEventListener("keydown", preventFormSubmitOnEnter);

            // Remove event listener on cleanup
            return () => {
                formElement.removeEventListener("keydown", preventFormSubmitOnEnter);
            };
        }
    }, [formElement, preventFormSubmitOnEnter]);

    useEffect(() => {
        // When the component is mounted, set the form element
        setFormElement(document.getElementById("add_student_form"));
    }, []);

    const on_change = e => {

        // Reset the added student message
        // when admin starts typing again
        if (is_added_student)
        {
            dispatch( resetAddStudent() );
        }

        set_form_data(prev_state => ({
            ...prev_state,
            [e.target.name]: e.target.value
        }))
    };

    // Also reset the added student message when the admin focuses on the form fields
    useEffect(() => {
        const formFields = document.querySelectorAll("#add_student_form input, #add_student_form textarea");
      
        const handleFocus = () => {
            if (is_added_student)
            {
                dispatch( resetAddStudent() );
            }
        };
      
        formFields.forEach((field) => {
          field.addEventListener("focus", handleFocus);
        });
      
        return () => {
          formFields.forEach((field) => {
            field.removeEventListener("focus", handleFocus);
          });
        };
    }, [is_added_student, dispatch]);

    const on_submit = e => {
        e.preventDefault();

        if (!student_id)
        {
            dispatch( resetAddStudent() );
            toast.error("Please provide a student number.");
        }
        else if (!student_name)
        {
            dispatch( resetAddStudent() );
            toast.error("Please provide a student name.");
        }
        else if (!preferred_name)
        {
            dispatch( resetAddStudent() );
            toast.error("Please provide a preferred name.");
        }
        else
        {
            // const trial = (trial_option === "Yes") ? true : false
            // console.log("AddStudent.js >> Adding student = " + student_name + ", student_id = " + student_id );
            const student_data = { student_name, student_id, preferred_name };
            dispatch( addStudent(student_data) );
        }
    };

    return (
        is_adding_student ? <Spinner /> :
        (
            <>
            <div className="bg-gradient-to-b from-hots-green-1 to-hots-green-2 ... text-center">
                <div className="login_container">
                    <section className="headless_title">
                        <h1 className="flex items-center justify-center"><FaUserPlus className="mr-8"/>Add a Student</h1>
                        {/* <h2>Create an account for a Teacher</h2> */}
                    </section>
                    
                    <section className="form">
                        <form id="add_student_form" onKeyDown={preventFormSubmitOnEnter}>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Student Number:</label>
                                <input type="text" className="form_control" id="student_id" name="student_id"
                                    value={student_id} placeholder="Enter a unique student number (SYL####)" onChange={on_change} />
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Student Name:</label>
                                <input type="text" className="form_control" id="student_name" name="student_name"
                                    value={student_name} placeholder="Enter student's full name" onChange={on_change} />
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Preferred Name:</label>
                                <label className="text-gray-200 font-regular text-lg">The name to be used in progress reports.</label>
                                <label className="text-gray-200 font-regular text-lg">It is usually the student's first name.</label>
                                <label className="text-gray-200 font-regular text-lg">E.g. John, Yan Yan, etc.</label>
                                <input type="text" className="form_control" id="preferred_name" name="preferred_name"
                                    value={preferred_name} placeholder="Enter student's preferred name" onChange={on_change} />
                            </div>

                            <div className="form_group">
                                <button className="btn btn_block" onClick={on_submit}>Add to System</button>
                            </div>

                            {
                                is_added_student && new_student &&
                                <div>
                                    <div className="text-hots-purple font-regular text-lg text-center">Student added successfully:</div>
                                    <div className="text-hots-purple font-bold text-xl text-center">{new_student.student_name} ({new_student.student_id})</div>
                                </div>
                            }

                            {
                                is_error_adding_student && message_adding_student &&
                                <div>
                                    <div className="text-hots-red font-bold text-xl text-center">{message_adding_student}</div>
                                </div>
                            }
                        </form>
                    </section>
                </div>

                <FooterAdmin />
            </div>
            </>
        )
    )
};

export default AddStudent;